@import "../variable/variables";
.footer {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  &.is-quarter {
    height: 25vh !important;
  }

  &.is-half {
    height: 50vh !important;
  }

  &.is-one-third {
    height: 33vh !important;
  }

  &.is-three-quarter {
    height: 75vh !important;
  }

  &.is-full {
    height: 100vh !important;
  }
}
