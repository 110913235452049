@import "../variable/variables";

.context-menu {
  padding: 4px;
  cursor: pointer;
  width: 250px;
  position: absolute;
  row-gap: 0;
  display: flex;
  flex-direction: column;
  background-color: map-get($shades, white);
  border-radius: map-get($sizes, s);
  border: 1px solid map-get($shades, sliver);
  animation: openMenu .7s ease-out;
  .row {
    position: relative;
    border-radius: calc( map-get($sizes, s) - 4px);
    padding: map-get($sizes, s) map-get($sizes, xs) map-get($sizes, s) map-get($sizes, m);
    display: flex;
    width: 100%;
    align-items: end;

    &:hover {
      background-color: map-get($shades, light);
    }

    .menu-item {
      width: calc(100% - 75px);
      display: flex;

      font-size:  map-get($sizes, m);
      font-weight: 400;
      align-items: center;
      .icon{
        color: map-get($shades, mute);
        margin-right: map-get($sizes, s);
        font-size:  map-get($sizes, l);
      }
    }

    .menu-action {
      width: 75px;
      display: flex;
      align-items: center;
      justify-content: end;
      font-size:  map-get($sizes, m);
      color: map-get($shades, sliver);
    }
  }
}

@keyframes openMenu {
  from {
    opacity: 0.5;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 750px;
  }
}