.avatar {

}
@mixin width-atr($i){
  width: $i;
}
.list-avatar {
  display: flex;
  list-style-type: none;
  margin: auto;
  padding-left: 12px;
  flex-direction: row;
  .avatar{
    background-color: #596376;
    color: #ffffff;
    display: block;
    font-family: sans-serif;
    width: map-get($sizes, l);
    height: map-get($sizes, l);
    border: 2px solid map-get($shades, white);
    border-radius: 50%;
    line-height: 45px;
    text-align: center;
    transition: margin 0.1s ease-in-out;
    overflow: hidden;
    margin-left: -10px;
    &:first-child {
      z-index: 5;
    }
    &:nth-child(2) {
      z-index: 4;
    }
    &:nth-child(3) {
      z-index: 3;
    }
    &:nth-child(4) {
      z-index: 2;
    }
    &:nth-child(5) {
      z-index: 1;
    }
    &:last-child {z-index: 0}
    img{width:100%}
  }
  &:hover {
    .avatars__item {
      margin-right:10px;
    }
  }
}
//.list-avatar {
//
//
//  position: relative;
//  display: flex;
//  flex-direction: row;
//  gap: 0;
//
//  img {
//    position: absolute;
//    transform: translateX(-50%);
//    width: map-get($sizes, l);
//    height: map-get($sizes, l);
//    border: 2px solid map-get($shades, white);
//    border-radius: 50%;
//
//    &.avatar:nth-child(2) {
//      left: 20px;
//      z-index: 1;
//    }
//
//    &.avatar:nth-child(3) {
//      left: 40px;
//      z-index: 2;
//    }
//
//    &.avatar:nth-child(4) {
//      left: 60px;
//      z-index: 3;
//    }
//  }
//}