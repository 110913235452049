@import "../variable/variables";

.breadcrumb {
  display: flex;
  list-style-type: none;

  .breadcrumb-item {
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: map-get(map-get($colors, primary), main) !important;
    & + .breadcrumb-item::before {
      font-family: 'Duck', serif;
      display: inline-block;
      padding-right: .5rem;
      padding-left: .5rem;
      color: map-get($shades, disable);
      content: "F";
    }
    &.active{
      color: map-get(map-get($colors, shade), main) !important;
    }
  }
}