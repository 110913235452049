.hljs-tag{
  color: #4b7f47;
  &+.hljs{
    &-string{
      color: #4b7f47;
    }
    &-attr{
      color: #ff7e10;
    }
    &-name{
      color: #da00aa;
    }
  }
}