@import "../variable/variables";

#special {
  height: calc(100vh - 2 * map-get($sizes, s));
  //- 2 * map-get($sizes, s)
  overflow-y: auto;
}

.border-special {
  border-left: 1px solid map-get($shades, sliver);
}

#dashboard {
  //background: map-get($shades, light);
  gap: map-get($sizes, s);
  padding: map-get($sizes, s);
}

@media screen and (max-width: $breakpoint-l) {
  #dashboard {
    gap: 0 !important;
    padding: 0 !important;
  }
  #special {
    border: 0 solid map-get($shades, sliver);
    border-radius: 0!important;
  }
  .border-special {
    border-left: 0px solid map-get($shades, sliver);
  }

}

.sidebar {
  // responsive
  // main
  //margin: map-get($sizes,s) 0 map-get($sizes,s) map-get($sizes,s);
  //border: 1px solid map-get($shades, sliver);
  border-radius: map-get($sizes, m);
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 200;
  justify-content: space-between;
  top: map-get($sizes, s);
  font-size: 16px;
  overflow-x: hidden;
  transition-duration: .1s;

  & * {
    transition-duration: 0.25s;
  }


  .sidebar-header {
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 0px 16px;

    .sidebar-brand > img {
      display: block !important;
      width: 32px;
      height: 32px;
    }

  }

  .sidebar-main {
    max-height: calc(100vh - 75px * 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    flex: 1;

    .sidebar-mobile-collapse {
      display: none;
    }

    .sidebar-items {
      max-height: calc(70vh );

      .item {
        .sidebar-icon {
          color: map-get($shades, disable) !important;
          width: map-get($sizes, xl);
          height: map-get($sizes, xl);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .sidebar-btn {
          padding: 8px 8px 8px 0px;
          display: flex !important;
          gap: 0;
          &:hover{
            .sidebar-icon{
              color: map-get($shades, black)!important;
            }
            .item-text{
              color: map-get($shades, black)!important;
            }
          }

        }

        .item-text {
          color: map-get($shades, disable) !important;
          padding-right: map-get($sizes, xxl);
          flex-grow: 1;
          display: flex;
          justify-content: center;
          flex-direction: row;
          flex-flow: column wrap;
          white-space: nowrap;
          font-weight: 500;
          font-size: 17px !important;
        }

        &.show {

          > :not(.item-collapse) {
            color: map-get($shades, white) !important;
            &:hover{
              color: map-get($shades, black) !important;
            }
          }
        }
      }

      .side-sub {
        opacity: 1;
        contain: strict;
        background: $primary-light;
        position: absolute;
        right: 8px;
        margin-left: auto;
        margin-right: auto;
        height: 48px;
        width: 0px;
        border-radius: map-get($sizes, m);
        z-index: -1;
        transition: .15s ease;
        transition-property: width, transform, opacity;
      }

    }


    .item {
      padding-left: 12px;
      padding-right: 8px;
      display: block;
      font-weight: 500;

      .item {
        padding-right: 0;
      }


        .sidebar-btn.active {
          border-radius: map-get($sizes, m);
          background-color: map-get($shades, black);
          .sidebar-icon{
            color: map-get($shades, white)!important;
          }
          .item-text{
            color: map-get($shades, white)!important;
          }
          &:hover{
            .sidebar-icon{
              color: map-get($shades, white)!important;
            }
            .item-text{
              color: map-get($shades, white)!important;
            }
          }
        }



      &.show {
        .sidebar-btn[data-expand="expand"]{
          border-radius: map-get($sizes, m);
          .sidebar-icon{
            color: map-get($shades, white)!important;
          }
          .item-text{
            color: map-get($shades, white)!important;
          }
          &:hover{
            .sidebar-icon{
              color: map-get($shades, black)!important;
            }
            .item-text{
              color: map-get($shades, black)!important;
            }
          }
        }
        .item-collapse {
          max-height: 900px;
        }
      }
      .item-collapse {
        width: 100%;
        display: flex;
        flex-flow: column;
        max-height: 0px;
        transition: max-height .45s;
        overflow: hidden;
      }


      .item {
        color: map-get($shades, disable);
        font-weight: 500;
        margin-left: calc(12px + (20px * .5) + 2px);
        border-left: 1px solid map-get($shades, disable);
        font-size: 15px;


        //&:last-child {
        //  //position: relative;
        //  border-left: 0 solid map-get($shades, disable);
        //  padding-bottom: 15px;
        //
        //  &::before {
        //    display: flex;
        //    content: "";
        //    position: inherit;
        //    left: 0;
        //    top: 0px !important;
        //    width: 2px;
        //    height: calc(50% - 12px);
        //
        //    background-color: map-get($shades, disable);
        //
        //  }
        //
        //  &::after {
        //    display: flex;
        //    content: "";
        //    position: inherit;
        //    left: 0;
        //    border-radius: 0 0 0 12px;
        //
        //    top: calc(50% - 12px) !important;
        //    width: 8px;
        //    height: 8px;
        //    border-left: 2px solid map-get($shades, disable);
        //    border-bottom: 2px solid map-get($shades, disable);
        //  }
        //}

      }
    }
  }

  .sidebar-bottom {
    border-top: 1px solid map-get($shades, darkgray);
    grid-area: sidebar-bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 8px;
  }

  &.hide {
    .sidebar-header {
      justify-content: center;

      .sidebar-brand {
        width: 32px;
      }

      & > :not(.sidebar-brand) {
        max-width: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .sidebar-main {
      .sidebar-items {
        .side-sub {
          left: 8px;
          right: 0px;
        }

        .item {

          display: flex;
          justify-content: center;
          overflow: hidden;

          .item-collapse {
            padding: 0;
            max-width: 0;
            max-height: 0;
            white-space: nowrap;
            overflow: hidden;
          }

          min-width: auto !important;

          .sidebar-btn {
            width: 48px !important;
            padding:8px 0;

            :not(.sidebar-icon), {
              padding: 0;
              max-width: 0;
              white-space: nowrap;
              overflow: hidden;
            }
          }

        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-l) {
    background: map-get($shades, mute);
    position: fixed;
    justify-content: start;
    top: 0;
    height: 100vh;
    display: flex;
    margin: 0;
    flex-flow: column;
    align-content: center;
    max-width: 256px;
    width: 100%;
    border-radius: 0;
    transition-duration: .1s;
    border: 0;
    box-sizing: border-box;
    border-right: 2px solid map-get($shades, sliver);
    &.hide {
      max-width: 0;
      left: -1px;

      .sidebar-main {
      }
    }
    .sidebar-main {
      //background-color: map-get($shades, white) !important;
      padding-right: 12px !important;
      bottom: 0;
      left: 0;
      align-items: center;
      border-radius: 12px;
      //position: absolute !important;
      z-index: 201;

      .sidebar-items {
        .side-sub {
          display: none;
        }

        .item {

          .item {
            margin-left: calc(14px + (20px * .5) - 1px) !important;

          }
          &.show {
            .sidebar-btn[data-expand="expand"]{
              border-radius: map-get($sizes, m);
              .sidebar-icon{
                color: map-get($shades, black)!important;
              }
              .item-text{
                color: map-get($shades, black)!important;
              }
              &:hover{
                .sidebar-icon{
                  color: map-get($shades, black)!important;
                }
                .item-text{
                  color: map-get($shades, black)!important;
                }
              }
            }
            .item-collapse {
              max-height: 900px;
            }
          }
        }
      }

      .sidebar-mobile-collapse {
        position: fixed;
        width: 48px;
        height: 48px;

        border-radius: 12px;
        background-color: map-get($shades, darkgray);
        color: map-get($shades, white);
        bottom: 35px;
        left: 35px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        z-index: 61 !important;
      }

      &.collapse {
        width: 50vw;
        min-width: 320px !important;
      }
    }
    .sidebar-header {
      //display: none !important;
    }
    &:hover {
    }
    .sidebar-bottom {
      //visibility: hidden;
      display: none !important;
    }
  }
}



