@import "../variable/variables";

.help {
  overflow: hidden;
  transition-duration: 0.45s;
  position:absolute;
  z-index: 2;
  transform:translateY(calc(50% - 4px));
  top: calc(-50% - 8px);
  right: calc(16px * .5 + 12px);
  opacity: 0;
  padding: 12px;
  font-size: 13px!important;
  border-radius:12px ;
  background: map-get($shades, mute);
  color: map-get($shades, white);
  //display:none; /* hide by default */
  &.active{
    @include expandMenu;
  }
}
.accordion{
  .accordion-item{
    max-height: 0;
    //overflow: ;
    &.active{
      @include openMenu(520);
    }
  }
}

.show.help:before {
  display:block;
}

.show.help:before, .show.help:after {
  display:block;
}
