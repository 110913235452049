@import "../variable/variables";
.tabs {
  display: flex;
  position: relative;
  &.vertical{
    flex-direction: column;
  }
  .tab-sub{
    opacity: 1;
    contain: strict;
    background: $primary-light;
    position: absolute;
    top: 9px;
    width: 54px;
    left: 0;
    border-radius: 4px;
    z-index: 0;
    height: 32px;
    transition: .15s ease;
    transition-property: width,transform,opacity;
  }
  .tab-item {
    position: relative;
    cursor: pointer;
    padding:16px 12px;
    font-size: 15px;
    font-weight: $text-bold;
    color: map-get($shades,disable);
    &:hover{
      color: map-get($shades,darkgray);
    }
    &.active{
      color:map-get($shades,black);
      &:before {
        box-sizing: border-box;
        content: "";
        display: block;
        position: absolute;
        height: 0;
        left: 9px;
        right: 9px;
        bottom: 0;
        border-bottom: 2px solid;
      }
    }

  }
  /* Thêm animation splice từ trái sang phải */
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  /* Áp dụng animation cho phần tử cần hiệu ứng */
  .slide-in {
    animation: slideIn 0.5s forwards;
  }

  .slide-out {
    animation: slideOut 0.5s forwards;
  }

}
.tab-content {
  transition: opacity 0.3s ease;

  // Ẩn phần content khi không được chọn
  &.active {
    display: block;
    opacity: 1;
  }

  // Hiển thị phần content được chọn
  &:not(.active) {
    display: none;
  }
}