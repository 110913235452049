@import "../variable/variables";
.chips{
  @mixin chipsMediumAtr {
    box-sizing: border-box !important;
    cursor: pointer;
    color: map-get($shades, disable);
    display: flex;
    align-items: center;
    justify-content: center;
    height: map-get($InputSize, s) !important;
    padding: 3px 16px ;
    font-size: 13px !important;
    line-height: 150% !important;
    border-radius: 80px;
    width: fit-content;
    font-weight: $text-bold;
  }
  @include chipsMediumAtr;
  border: 1px solid map-get($shades, sliver);
  &:hover{
    color: map-get(map-get($colors, primary), main);
    border: 1px solid map-get(map-get($colors, primary), main);
  }
  &.active{
    border: 1px solid map-get(map-get($colors, primary), main);
    color: map-get(map-get($colors, primary), main);
  }
}