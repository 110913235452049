.pagination{
  border-radius: 8px;
  height: 40px;
  .pag{
    &-left{

    }
    &-right{
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 12px;
  a{
    font-size: 16px;
    .is-disabled{
      color: map-get($shades,disable);
    }
  }
  .pagination-previous{

  }
  ul{
    list-style-type: none;

    li{
      font-size: 16px!important;
      padding: 4px 12px;
      &::marker{
        content: "";
      }
      border-radius: 8px;
      border: 1px solid map-get($shades,sliver);
      .is-current{
        color:map-get($shades,disable);
        cursor: default;
        user-select: none;
      }
    }
    gap: 6px;
    display: flex;
    flex-direction: row;
    list-style-type: none;
  }
}