@import "../variable/variables";
h1,h2,h3,h4,h5,h6,p{
  margin: 0px;
}
.is-fill {
  height: 100%;
  width: 100%;
}

.is-fill-x {
  width: 100% !important;
}

.is-hug {
  width: fit-content;
  height: fit-content;
}

.is-hug-x {
  width: fit-content;
}

.is-hug-y {
  height: fit-content;
}

.is-fill-y {
  height: 100% !important;
}

.is-full-y {
  height: 100vh !important;
}

.is-full-x {
  width: 100vw;
  position: relative;
  left: 0%;
  right: 0%;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}

@each $offSetName, $offSet in $sizes {
  .size-#{$offSetName} {
    & > svg {
      width: $offSet;
      height: $offSet;
    }

    width: $offSet;
    height: $offSet;
  }
  .size-y-#{$offSetName} {
    height: $offSet;

  }

  .size-x-#{$offSetName} {
    width: $offSet;
  }
  .size-#{$offSetName}-x2 {
    width: calc(#{$offSet} * 10);
    height: calc(#{$offSet} * 10);
  }
  .size-y-#{$offSetName}-x2 {
    height: calc(#{$offSet} * 10);
  }

  .size-x-#{$offSetName}-x2 {
    width: calc(#{$offSet} * 10);
  }
}

@each $offSetName, $offSet in $sizes {
  .rounded-#{$offSetName} {
    border-radius: $offSet !important;
  }
  .rounded-t-#{$offSetName} {
    border-radius: $offSet $offSet 4px 4px  !important;
  }
  .rounded-r-#{$offSetName} {
    border-radius: $offSet  4px 4px  $offSet  !important;
  }
  .rounded-l-#{$offSetName} {
    border-radius: 4px $offSet $offSet 4px !important;
  }
  .rounded-r-#{$offSetName} {
    border-radius: 4px $offSet $offSet  4px  !important;
  }
  .rounded-tl-#{$offSetName} {
    border-radius:  $offSet 4px 4px 4px!important;
  }
  .rounded-tr-#{$offSetName} {
    border-radius:  4px $offSet  4px 4px!important;
  }
  .rounded-br-#{$offSetName} {
    border-radius:  4px 4px $offSet   4px!important;
  }
  .rounded-bl-#{$offSetName} {
    border-radius:  4px 4px 4px $offSet!important;
  }
  .rounded-l-#{$offSetName} {
    border-radius:  $offSet 4px 4px $offSet !important;
  }
  .rounded-b-#{$offSetName} {
    border-radius: 4px 4px $offSet $offSet   !important;
  }
}

.rounded {
  &-x {
    border-radius: 0 !important;
  }

  overflow: hidden;
  border-radius: 500px !important;
}

@mixin text-atr {
  line-height: 150%;
  font-weight: $text-regular;
  &.unwrap {
    white-space: nowrap !important;
  }
}

.text {
  &-italic {
    font-style: italic;
  }

  &-xs {
    @include text-atr;
    font-size: 13px !important;
  }

  &-s {
    @include text-atr;
    font-size: 15px !important;
  }

  &-m {
    @include text-atr;
    font-size: 17px !important;
  }

  &-l {
    @include text-atr;
    font-size: 21px !important;
  }

  &-xl {
    @include text-atr;
    font-size: 25px !important;
  }

  &-xxl {
    @include text-atr;
    font-size: 33px !important;
  }

  &-uppercase {
    text-transform: uppercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
}

@for $i from 1 through 5 {
  .is-#{$i}-line {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@mixin title-atr {

  line-height: 120%;
  font-weight: $text-bold;
  &.unwrap {
    white-space: nowrap !important;
  }
}

.title {

  &-xs {
    @include title-atr;
    font-size: 12px !important;
  }

  &-s {
    @include title-atr;
    font-size: 14px !important;
  }


  &-m {
    @include title-atr;
    font-size: 16px !important;
  }

  &-l {
    @include title-atr;
    font-size: 20px !important;
  }

  &-xl {
    @include title-atr;
    font-size: 24px !important;
  }

  &-xxl {
    @include title-atr;
    font-size: 32px !important;
  }
}

@mixin h-atr {
  margin: 0;
  line-height: 120%;
  font-weight: $text-extraBold;
  &.unwrap {
    white-space: nowrap !important;
  }
  //color: map-get($shades, black)
}

.h {
  font-family: 'NVN Valky', sans-serif !important;

  &-xs {
    @include h-atr;
    font-size: 16px !important;
  }

  &-s {
    @include h-atr;

    font-size: 20px !important;
  }

  &-m {
    @include h-atr;
    font-size: 24px !important;
  }

  &-l {
    @include h-atr;
    font-size: 32px !important;
  }

  &-xl {
    @include h-atr;
    font-size: 40px !important;
  }

  &-xxl {
    @include h-atr;
    font-size: 48px !important;
  }

  h {
    &6 {
      @include h-atr;
      font-size: 16px !important;
    }

    &5 {
      @include h-atr;

      font-size: 20px !important;
    }

    &4 {
      @include h-atr;
      font-size: 24px !important;
    }

    &3 {
      @include h-atr;
      font-size: 32px !important;
    }

    &2 {
      @include h-atr;
      font-size: 40px !important;
    }

    &1 {
      @include h-atr;
      font-size: 48px !important;
    }
  }
}
.y-quarter {
  height: 25vh !important;
}

.y-half {
  height: 50vh !important;
}

.y-one-third {
  height: 33vh !important;
}

.y-three-quarter {
  height: 75vh !important;
}
.y-full {
  height: 100vh !important;
}


.x-quarter {
  width: 25vw !important;
}

.x-half {
  width: 50vw !important;
}

.x-one-third {
  width: 33vw !important;
}

.x-three-quarter {
  width: 75vw !important;
}
.x-full {
  width: 100vw !important;
}
.is-calc{
  height: calc( 25vh - 48px);
}