.tool-bar{
  .select-expand{
    display: none;
    flex-wrap: wrap;
    position: absolute;
    z-index: 12;
    padding: 4px;
    top: 32px;
    left: 0;
    background: map-get($shades, "white");
    &.is-open{
      display: grid!important;
    }
  }
}
.context{
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
  overflow: visible;
  min-height: 30px;
  border: 1px dashed white;
  border-radius: 8px;
  &:hover,&.context-active{
    border: 1px solid map-get($shades, "light");
    .context-place{
      visibility: visible;
    }
  }
  .editable{
    .cell-editor{
      word-wrap: break-word;
      position: relative;
      .cell-selector{
        display: none;
        background-color: map-get($shades, "light");
        padding: 0px 2px;
        border: 1px solid map-get($shades, "light");
        border-radius: 4px;
        color: map-get($shades, "disable");
      }
      .is-open{
        &.cell-selector{
          display: flex;
        }
      }
      box-sizing: border-box;
      &:hover{
        .cell-selector{
          display: flex;
         }
      }


      &::selection{
        background: map-get($shades, "sliver");
      }
    }

    margin: 0;
  }
  .context-place{
    &>*:hover{
      color: black;
    }

    background-color: map-get($shades, "light");
    padding: 0px 2px;
    border: 1px solid map-get($shades, "light");
    border-radius: 4px;
    color: map-get($shades, "disable")!important;
    display: flex;
    flex-direction: column;
    z-index: 12;
    column-gap: 4px;
    position: absolute;
    right: 0px;
    top:0px;
    transform: translate(100%,10%);
    visibility: hidden;
  }


  [contenteditable=true]:empty:not(:focus):before{
    content:"input";
    color:grey;
    font-style:italic;
  }
  [contenteditable]{
    padding: 4px;
  }
  [contenteditable]:focus {
    border-radius: 4px;
    outline: 1px dashed $primary;
  }
  .table-widget{
    position: relative;
    bottom: 0;
    left: 0;
    .row-widget{
      cursor: e-resize;
      position: absolute;
      width: 12px;
      background:$primary;
      z-index: 2;
      bottom: 0;
      height: 120px;
    }

  }
}
.is-link{
  color: $primary;
  text-decoration: underline ;
  font-style: italic!important;

}
.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: e-resize;
  user-select: none;
}
.resizer:hover,
.resizing {
  width: 5px;
  background-color: map-get($shades,sliver);
  border-right: 2px solid blue;
}
.table th {
  position: relative;
}
.resizable {
  border: 1px solid gray;
  height: 100px;
  width: 100px;
  position: relative;
}