@import "../variable/variables";

.bottom-menu {
  display: flex;
  cursor: pointer;
  position: fixed!important;
  z-index: 1200;
  bottom:  5%;
  left: 50%;
  background-color: rgba(map-get($shades, mute), .5);
  min-width: 128px;
  backdrop-filter: blur(2px);
  color: map-get($shades, light);
  padding: 4px;
  transform: translateX(-50%);
  border-radius: 12px;
  .items {
    transition: all .3s linear!important;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .menu-right{
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .menu-collapse-button{
    display: none;
  }
}

@media screen and (max-width: $breakpoint-m) {
  .bottom-menu {
    .menu-collapse-button{
      display: flex;
    }
    //width: 90vw;
    &.menu-expand {
      min-width: 90vw;
      flex-direction: column-reverse;
      .menu-right{
        display: none;
      }
      .items {
        justify-content: end;
        min-width: 90%;
        max-height: 80vh;
        flex-direction: column-reverse;
        .item {
          width: 100%;
        }
      }
    }
    &.menu-collapse {
      .items{
        min-width: 20%;
        .item.item-collapse{
            display: none !important;
        }
      }

    }
  }
}