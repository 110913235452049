@import "../variable/variables";

.tooltip {
  position:relative; /* making the .tooltip span a container for the tooltip text */
  transition: all ease-in .1s;
}
.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position:absolute;

  /* vertically center */
  top:0;
  transform:translateY(calc(-100% - 4px));

  /* move to right */
  //left:100%;
  right: calc(16px * .5);
  /* basic styles */
  max-width:200px;
  min-width: 150px;
  padding:12px;
  font-size: 13px!important;
  border-radius:12px 12px 0 12px ;
  background:map-get($shades, mute);
  color: map-get($shades, white);

  display:none; /* hide by default */
}
.tooltip:hover:before {
  display:block;
}

.tooltip:hover:before, .tooltip:hover:after {
  display:block;
}