.loading-screen{
  width: 100%;
  height: 3vh;
  border-radius: 20px;
  .loading-process{
    border-radius: 20px;
    height: 100%;
    background: linear-gradient(45deg, #000000 0%, #000000 5%,transparent 5%, transparent 10%, #000000 10%, #000000 15%,transparent 15%,transparent 20%, #000000 20%, #000000 25%,transparent 25%,transparent 30%, #000000 30%, #000000 35%,transparent 35%,transparent 40%, #000000 40%, #000000 45%,transparent 45%,transparent 50%, #000000 50%, #000000 55%,transparent 55%,transparent 60%, #000000 60%, #000000 65%,transparent 65%,transparent 70%, #000000 70%, #000000 75%,transparent 70%,transparent 80%, #000000 80%, #000000 85%,transparent 85%,transparent 90%, #000000 90%, #000000 95%,transparent 95%);
    background-size: 12em 12em;
    opacity: 1
  }
  @keyframes loadingProcess {

  }
}
