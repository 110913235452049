@import "../variable/variables";
@import "../Animation/animation";

.navbar {
  min-height: 60px;
  transition-duration: 1s;
  display: grid !important;
  grid-template-columns: .2fr 1fr  ;
  grid-template-areas: "left main ";
  //height: 4rem;
  width: 100%;
  min-width: 50px;
  top: 0;
  z-index: 2;
  &.hidden{
    height: 4rem;
    width: 100%;
    z-index: 2;
    position: fixed!important;
    top: -80px!important;
    transition: 0.4s linear;

  }
  .nav{
    &-brand {
      display: flex;
      grid-area: left;
      justify-items: start;
      justify-content: start;
      align-items: center;
    }

    &-main {
      grid-area: main;
      display: flex;
      align-items: center;
      .nav-items {
        position: relative;
        display: flex;
        justify-content: space-between;
        .nav-item {
          margin: 6px ;
          padding: 6px 12px;
          text-align: center;
          flex: 1;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          transition: all .2s ease-in;
          &:hover{
            background-color: #eeeeee;
          }
          &.is-active{
            background-color: #eeeeee;
          }
        }
      }
    }

    &-mobile {
      grid-area: right;
      display: none;
      justify-items: end;
      justify-content: end;
      align-items: center;
    }

    .nav-item-collapse {
      display: none !important;
    }


  }


  @media screen and (max-width: $breakpoint-l) {
    grid-template-areas:
                "left right"
                "main main" ;
    grid-template-columns: 1fr 1fr  ;
    .nav-item-collapse {
      display: flex !important;
      right: 0;
      bottom: 0;
    }
    .nav-items {
      justify-content: start;
      transition: max-height .2s;
      max-height: 0;
      .nav-item {
        opacity: 0;
        animation-name: alignText;
        animation-duration: .2s;
        display: none ;
        padding: 12px 24px ;
        border-radius: 0!important;

      }

      &.expand {
        max-height: 100vh;
        position: relative;
        display: block;

        .nav-item {
          opacity: 1;
          justify-content: space-between;
          display: flex ;
        }
      }
    }
  }
  .nav-mobile {
    grid-area: right;
    display: none;
    justify-items: end;
    justify-content: end;
    align-items: center;
  }
}

@media screen and (max-width: $breakpoint-l) {
  .navbar {
    width: 100%;
    position: sticky !important;
    top: 3rem ;
    max-height: 90vh;
    backdrop-filter: blur(2px);
    .nav-mobile {
      grid-area: right;
      display: flex;
      justify-items: end;
      justify-content: end;
      align-items: center;
    }
    .nav-items{
      .nav-item{
        padding:8px 0 !important;
      }
      &.expand{
        width: 100%;
        //height: 20px;
        padding: 12px 0;
        border-radius: 8px;
        border: 1px solid white;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }

  }
}

.navbar-2 {
  min-height: 60px;
  transition-duration: 1s;
  display: flex !important;
  width: 100%;
  min-width: 50px;
  top: 0;
  z-index: 2;
  transition: 0.2s linear;

  &.hidden {
    width: 100%;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0px;

  }

  .nav {
    &-brand {
      display: flex;
      align-items: center;
    }

    &-main {
      display: flex;
      flex: 1;
      align-items: center;

      .nav-items {
        position: relative;
        display: flex;
        justify-content: space-between;

        .nav-item {
          display: flex;
          align-items: center;
          margin: 6px;
          padding: 6px 12px;
          text-align: center;
          flex: 1;
          border-radius: 12px;
          justify-content: center;
          transition: all .2s ease-in;

          &:hover {
            background-color: #eeeeee;
          }

          &.is-active {

          }
        }


      }
    }

    &-mobile {
      grid-area: right;
      display: none;
      justify-items: end;
      justify-content: end;
      align-items: center;
    }

    .nav-item-collapse {
      display: none !important;
    }


  }

  @media screen and (max-width: $breakpoint-l) {
    .nav-items {
      #navbar-open {
        transform: translate(0, 0);
        min-width: 100vw !important;
      }
    }
  }
}

#navbar-open {
  max-height: 0vh !important;
  overflow: hidden;
  top: calc(100% + map-get($sizes, s));
  right: 0;
  transition: max-height .17s linear;

  &.open {
    max-height: 100vh !important;
    border-bottom: 1px solid map-get($shades, sliver);
  }
}
