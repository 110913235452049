$icon: (
//group:icon
//group:activity
campfire: "!",
cart: "\"",
rocket: "#",
//group:align
align-bottom: "$",
align-horizontal-center: "%",
align-left: "&",
align-right: "'",
align-top: "(",
align-vertical: ")",
insert-above: "*",
insert-below: "+",
insert-left: ",",
insert-right: "-",
justify-center: ".",
justify-left: "/",
justify-right: "0",
vertical-center: "1",
//group:analytics
bar-chart: "2",
caculator: "3",
line-chart: "4",
poll: "5",
rule: "6",
//group:arrow
arrow-all-down: "7",
arrow-all-up: "8",
arrow-down: "9",
arrow-left: ":",
arrow-right: ";",
arrow-up: "<",
down: "=",
download: ">",
drag: "?",
expand: "@",
left-direction: "A",
nesw-resize: "B",
nw-es-resize: "C",
open-link: "D",
redo: "E",
right-direction: "F",
rotate: "G",
send-reponse: "H",
sign-in: "I",
sign-out: "J",
tri-down: "K",
tri-left: "L",
tri-right: "M",
tri-up: "N",
undo: "O",
up-direction: "P",
up-down: "Q",
upload: "R",
x-resize: "S",
y-resize: "T",
//group:building
house-2: "U",
lighthouse: "V",
store: "W",
torii-gate: "X",
//group:day
crescent-moon: "Y",
full-moon: "Z",
gibbous-moon: "[",
sun: "\\",
sunrise: "]",
sunset: "^",
//group:device
device-laptop: "_",
device-mobile-: "`",
device-mobile: "a",
device-mouse: "b",
device-multi: "c",
device-no-mobile: "d",
device-pc-tower: "e",
device-pc: "f",
device-server: "g",
device-tablet: "h",
device-window: "i",
//group:face
big-frown: "j",
neutral-face: "k",
silent-blank-face: "l",
smile-face: "m",
//group:file
camera: "n",
document-add: "o",
document-download: "p",
document-edit: "q",
document-error: "r",
document-image: "s",
document-private: "t",
document-record: "u",
document-upload: "v",
document-video: "w",
document: "x",
folder-add: "y",
folder-download: "z",
folder-error: "{",
folder-upload: "|",
folder: "}",
video-recorder: "~",
//group:geography
mountain: " ",
planet: "¡",
//group:layout
column: "¢",
header: "£",
layout: "¤",
open: "¥",
row: "¦",
table: "§",
//group:map
location: "¨",
map-pin: "©",
retangle-flag-1: "ª",
retangle-flag: "«",
world: "¬",
//group:media
image-add: "­",
image-download: "®",
image-upload: "¯",
image: "°",
//group:menu
circle: "±",
close: "²",
context-menu-1: "³",
context-menu-2: "´",
context-menu: "µ",
left-menu: "¶",
menu: "·",
right-menu: "¸",
//group:message
message-1: "¹",
message-2: "º",
message-error-1: "»",
message-error: "¼",
message-hash: "½",
message-new: "¾",
message-private: "¿",
message-time: "À",
message: "Á",
//group:music
music-backward: "Â",
music-double-note-1: "Ã",
music-double-note: "Ä",
music-forward: "Å",
music-next: "Æ",
music-note: "Ç",
music-pause: "È",
music-play: "É",
music-playlist: "Ê",
music-previous: "Ë",
music-random: "Ì",
music-repeate-one: "Í",
music-repeate: "Î",
music-speed: "Ï",
music-stop: "Ð",
subtitle: "Ñ",
//group:number
eight: "Ò",
five: "Ó",
four: "Ô",
nine: "Õ",
one: "Ö",
seven: "×",
six: "Ø",
three: "Ù",
two: "Ú",
zero: "Û",
//group:object
ballon: "Ü",
book: "Ý",
candy: "Þ",
pixal-lamp: "ß",
pointer-goal: "à",
pointer: "á",
tri-circle: "â",
umbrella: "ã",
//group:paragraph
capitalize: "ä",
decrease-fontsize: "å",
decrease-indent: "æ",
font: "ç",
function: "è",
header-1: "é",
header-2: "ê",
header-3: "ë",
header-4: "ì",
header-5: "í",
header-6: "î",
increase-fontsize: "ï",
increase-indent: "ð",
list: "ñ",
ordinal: "ò",
paragraph: "ó",
quote-1: "ô",
quote: "õ",
style-bold: "ö",
style-italic: "÷",
underline: "ø",
uppercase: "ù",
//group:security
bar-code-scan: "ú",
bar-code: "û",
eye-hide: "ü",
eye: "ý",
finger: "þ",
lock-open: "ÿ",
lock-password: "Ā",
lock: "ā",
password: "Ă",
qr-code-scan-1: "ă",
qr-code-scan: "Ą",
qr-code: "ą",
shield: "Ć",
//group:shape
rectangle-16-9: "ć",
rectangle-9-16: "Ĉ",
square: "ĉ",
//group:sound
head-phone: "Ċ",
mic-off: "ċ",
mic-on: "Č",
sound-device: "č",
sound-max: "Ď",
sound-medium-1: "ď",
sound-medium: "Đ",
sound-multi-device: "đ",
sound-mute: "Ē",
sound-no-device: "ē",
sound-none: "Ĕ",
sound-radio: "ĕ",
sound-small: "Ė",
television: "ė",
//group:support
book-1: "Ę",
book-2: "ę",
bookmark: "Ě",
checked: "ě",
circle-add: "Ĝ",
circle-danger: "ĝ",
circle-ignore: "Ğ",
circle-info: "ğ",
circle-question: "Ġ",
double-checked: "ġ",
heart-broken: "Ģ",
heart: "ģ",
like: "Ĥ",
timeline-event: "ĥ",
tri-danger: "Ħ",
tri-ignore: "ħ",
unlike: "Ĩ",
//group:time
time-countdown-1: "ĩ",
time-countdown: "Ī",
//group:traffic
barrier-2: "ī",
barrier: "Ĭ",
//group:ui
add: "ĭ",
al-sign: "Į",
attachments: "į",
background: "İ",
circle-1-4: "ı",
circle-2-4: "Ĳ",
circle-3-4: "ĳ",
circle-4: "Ĵ",
clipboard-fail: "ĵ",
clipboard-success: "Ķ",
component: "ķ",
copy-success: "ĸ",
copy: "Ĺ",
divide: "ĺ",
eye-droper-1: "Ļ",
eye-droper: "ļ",
fill-horizontal: "Ľ",
fill-vertical: "ľ",
home: "Ŀ",
hug-horizontal: "ŀ",
hug-vertical: "Ł",
link: "ł",
mirror: "Ń",
multiply: "ń",
notification-1: "Ņ",
notification-new: "ņ",
notification: "Ň",
pen: "ň",
scale: "ŉ",
setting-2: "Ŋ",
setting: "ŋ",
split: "Ō",
subtract: "ō",
trash-can-blank: "Ŏ",
trash-can: "ŏ",
wand: "Ő",
zoom-in: "ő",
zoom-out: "Œ",
zoom: "œ",
//group:user
profile: "Ŕ",
user-add: "ŕ",
user-edit: "Ŗ",
user-error: "ŗ",
user-group: "Ř",
user-remove: "ř",
user-search: "Ś",
user-users: "ś",
user: "Ŝ",
//group:vehicles
air-balloon: "ŝ",
cable-car: "Ş",
cano: "ş",
car-suv: "Š",
electronic-train: "š",
helicopter: "Ţ",
parachute: "ţ",
pirate-ship: "Ť",
plane-1: "ť",
plane-fight-1: "Ŧ",
plane-fight: "ŧ",
plane: "Ũ",
sailboat: "ũ",
ship: "Ū",
submarine: "ū",
tank: "Ŭ",
tractor: "ŭ",
zeepelin: "Ů",
//group:war
bomb: "ů",
boom: "Ű",
grenade: "ű",
nuclear-bomb: "Ų",
nuclear: "ų",
skull: "Ŵ",
//group:weather
big-wind: "ŵ",
cloud: "Ŷ",
lighning: "ŷ",
small-wind: "Ÿ",
snowflake: "Ź",
sun-cloud: "ź",
whirlwind: "Ż",
wind: "ż",
//group:work
bot-1: "Ž",
bot: "ž",
code-1: "ſ",
code: "ƀ",
fork-1: "Ɓ",
fork: "Ƃ",
issue-1: "ƃ",
issue-2: "Ƅ",
issue-3: "ƅ",
issue-4: "Ɔ",
issue-5: "Ƈ",
issue: "ƈ",
license: "Ɖ",
merge-1: "Ɗ",
merge: "Ƌ",
policy-1: "ƌ",
policy: "ƍ",
prom-1: "Ǝ",
prom: "Ə",
)