.is-title {
  position: relative;
  padding-left: 18px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 0;
    width: 8px;
    background:  map-get($shades,black);
    border-radius: 3px;
  }
}
.is-quote{
  background: map-get($shades,light);
  border-radius: 8px!important;
  position: relative;
  padding:  16px 16px 16px 24px!important;
  font-style: italic!important;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    width: 6px;
    background: map-get($shades,mute);
    border-radius: 4px;
  }
}
ul{
  margin: 6px;
  li::marker{
    content: "*  ";
  }
}
pre{
  padding: 24px 16px!important;
  margin: 0!important;
  border-radius: 8px;
}
.note{
  min-height: 75px;
  padding:  map-get($sizes,l);
  margin-bottom: map-get($sizes,xxl);
  border-radius: 12px;
  display: flex;
  background-color: map-get($shades,sliver);
  .note-content{
    display: block;
    .note-title {
      display: flex;
      align-items: center;
      padding-bottom:  map-get($sizes,s);
      font-size: 16px;
      font-weight: 600;
    }
    .note-info{
      font-size: 14px;
      line-height: 150%;
      font-weight: 300;
      color: map-get($shades,mute);
    }
  }
}
.rotate-90{
  transform: rotate(90deg);
}
.rotate-180{
  transform: rotate(-180deg);
}