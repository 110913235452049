body {
  width: 100%;
}
// for check
* {
   //outline: 1px solid #f00 !important;
}
@import "./elements/scrollbar";

.Duck {

  .code{
    height: fit-content;
    width: fit-content;
    color: $secondary;
    background-color: $secondary-light;
    padding:  4px 8px;
    margin: 0 4px;
    border-radius: 4px;
  }
  .is-scroll{
    overflow: scroll;
    &-x{
      overflow-x: auto;
    }
    &-y{
      overflow-y: auto;
    }
  }
  .is-overflow{
    overflow: hidden;
  }
  .overflow-auto{
    overflow: auto!important;
  }
  .is-overflow-x{
    overflow-x: hidden;
  }
  .fit-content{
    flex: fit-content;
  }
  * {
    box-sizing: border-box;
  }

  ul {
    li {
      list-style-position: inside
    }
  }
  @import "./variable/variables";
  @import "./layout/size";
  @import "./layout/spaces";
  @import "./layout/layout";
  @import "./elements/table";
  @import "./elements/button";
  @import "./elements/sidebar";
  @import "./elements/image";
  @import "./elements/hero";
  @import "./elements/input";
  @import "./elements/help";
  @import "./elements/tooltip";
  @import "./elements/Collapse";
  @import "./elements/Pagination";
  @import "./elements/BreadCrumb";
  @import "./elements/Editor";
  @import "./elements/Cursor";
  @import "./elements/card";
  @import "./elements/avatar";
  @import "./elements/image";
  @import "./elements/notification";
  @import "./elements/Navbar";
  @import "./elements/tabs";
  @import "./elements/chips";
  @import "./elements/modal";
  @import "./elements/footer";
  @import "./elements/shader";
  @import "./elements/loading";
  @import "./elements/vision";
  @import "./elements/syntax";
  @import "./elements/BottomMenu";
  @import "./elements/ContextMenu";
  @import "./layout/viewport";
  a{
    text-decoration: none;
    &.link-hover{
      &:hover{
        text-decoration: underline;
      }
    }
  }
  @each $shaderName, $shader in $shades {
    .text-#{$shaderName} {
      color: $shader !important;
    }
  }

  @each $shaderName, $shader in $shades {
    .bg-#{$shaderName} {
      background-color: $shader;
      color: map-get($shades, black)
    }
    .bg-hover-#{$shaderName}:hover {
      background-color: $shader;
      color: map-get($shades, black)
    }
    .text-#{$shaderName} {
      color: $shader;
    }
    .hover-#{$shaderName}{
      :hover{
        fill: $shader;
      }
    }
  }
  @mixin bg-variable($colorName) {
    .&-
  #($colorName) {
    background-color: $colorName;
  }
  }

  .bg {
    &-hover{
      &-primary:hover {
        background-color: $primary;
        color: map-get($shades, white);

        &-light {
          background-color: $primary-light;
          color: map-get($shades, black);

        }
        &-dark {
          background-color: $primary-dark;
          color: map-get($shades, black);

        }

        .active {
          background-color: $primary-active;
          color: map-get($shades, white);
        }
      }
      &-secondary:hover {
        background-color: $secondary;
        color: map-get($shades, white);

        .active {
          background-color: $secondary-active;
        }
      }
    }
    &-primary {
      background-color: $primary;
      color: map-get($shades, white);

      &-light {
        background-color: $primary-light;
        color: map-get($shades, black);

      }
      &-dark {
        background-color: $primary-dark;
        color: map-get($shades, black);

      }

      .active {
        background-color: $primary-active;
        color: map-get($shades, white);
      }
    }

    &-secondary {
      background-color: $secondary;
      color: map-get($shades, white);
      &-light {
        background-color: $secondary-light;
        color: $secondary;

      }
      .active {
        background-color: $secondary-active;
      }
    }
  }

  .text {
    &-primary {
      color: $primary;

    }
    &-semi{
      font-weight: $text-bold;
    }
    &-bold{
      font-weight: $text-extraBold;
    }


    &-secondary {
      color: $secondary;
    }
    &-border{
      color: transparent;
      //border: 3px solid map-get($shades, outline);
      -webkit-text-stroke-width:4px;
      -webkit-text-stroke-color: map-get($shades, white);
    }
  }

  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-justify{
    text-align: justify;
  }
  .text-indent{
    text-indent: 20px;
  }
}

.section {
  margin-bottom: 5rem !important;
}

.narrow {
  flex-basis: 0;

}

pre {
  border-radius: 12px;
  margin: 0;
}
.bg-example{
  height: 560px!important;
  overflow: hidden;
  background-image: repeating-linear-gradient(0deg, map-get($shades,white) 0px, map-get($shades,white) 4px,  rgba(255, 0, 0, 0.025) 4px ,  rgba(255, 0, 0, 0.05) 8px);
}
.text-balance{
}
.text-underline{
  position: relative;
  font-style: normal;
  font-weight: 600;
  &:after{
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -5px;
    border-radius: 4px;
    height: 5px;
    z-index: -1;
    background: map-get($shades,"sliver");
    animation: tora 2s linear ;
  }
  @keyframes tora {
    from {
      max-width: 0;
    }
    to{
        max-width: 100%;
    }

  }
}
.bg-skeleton{
  width: 100%;
  background: linear-gradient(180deg, #D9D9D9 -100.99%, rgba(217, 217, 217, 0.00) 71.29%);
}