@import "../variable/variables";

@mixin InputMediumAtr {
  box-sizing: border-box !important;
  cursor: text;
  height: map-get($InputSize, '') !important;
  padding: 0 0 0 8px !important;
  font-size: 15px !important;
  line-height: 1 !important;
  border-radius: 8px;
  width: fit-content;
  border: 1px solid map-get($shades, disable) !important;
  font-weight: $text-regular;
}

@mixin InputTinyAtr {
  height: map-get($InputSize, s) !important;
  padding: 0 16px !important;
  font-size: 13px !important;
  line-height: 1 !important;
  @include svgAtr(12);
}

@mixin openMenu($i) {
  max-height: #{$i}px;
}

@mixin expandMenu {
  opacity: 1;
}

@mixin InputLargeAtr {
  height: map-get($InputSize, l) !important;
  padding: 0 16px !important;
  font-size: 15px !important;
  line-height: 1.6 !important;
  @include svgAtr(16);
}


@mixin inputAtr {
  font-weight: 500 !important;
  box-sizing: border-box;
  @include InputMediumAtr;
  color: map-get($shades, mute) !important;
  border: 1px solid map-get($shades, sliver) !important;

  &::placeholder {
    font-weight: 400;
    color: map-get($shades, sliver) !important;
  }
  &:focus-visible {
    font-weight: 500;
    @include duckFocus(map-get(map-get($colors, primary), light));
    outline: 2px solid map-get(map-get($colors, primary), main) !important;
    color: map-get($shades, mute) !important;

    &:invalid {
      outline: 1px solid map-get(map-get($colors, secondary), main) !important;
      color: map-get($shades, mute) !important;
    }
  }
  &:invalid {
    outline: 1px solid map-get(map-get($colors, secondary), main) !important;
    color: map-get($shades, mute) !important;
  }
}

select {
  width: 100%;
}

.select {
  position: relative;
  cursor: pointer !important;
  width: 100% !important;
  background-color: map-get($shades, white);
  @include inputAtr;

  .select-title {
    &.placeholder {
      color: map-get($shades, sliver);
    }
  }

  &:disabled {
    background-color: map-get($shades, light);
  }

  .select-input {
    padding-right: 8px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-list {
    overflow-y: auto;
    left: 0;
    top: calc(100% + 8px);
    width: 100%;
    z-index: 2;
    font-weight: 500 !important;
    box-sizing: border-box;
    color: map-get($shades, mute) !important;
    position: absolute;
    row-gap: 4px;
    display: flex;
    flex-direction: column;
    background-color: map-get($shades, white);
    border-radius: map-get($sizes, s);
    max-height: 0px;
    transition-duration: 0.45s;

    &.active {
      border: 1px solid map-get($shades, sliver) !important;
      padding: 4px;
      @include openMenu(220);
    }

    .select-item {
      position: relative;
      border-radius: calc(map-get($sizes, s) - 4px);
      padding: map-get($sizes, xs);
      display: flex;
      align-items: end;

      & > * {
        width: 100%;
      }

      &:hover {
        background-color: map-get($shades, light);
      }
    }
  }

}

input, textarea, select {
  @include inputAtr;
  flex: fit-content;

  &:disabled {
    background-color: map-get($shades, light);
  }

}

textarea {
  resize: none;
  font-size: 17px !important;
  min-height: 100px;
  //height: auto;
  padding: 12px 16px !important;
  line-height: 120% !important;
}

.field {
  position: relative;
  &.disable{
    .label{
      color: map-get($shades, disable);
      font-weight:600;
      line-height: 120%;
      font-size: 15px;
    }
  }
  .add {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid map-get($shades, sliver);

    &.active {
      .add-list {
        padding: 4px;
        @include openMenu(220);
      }

      .add-input {
        border-bottom: 1px solid map-get($shades, sliver);

      }
    }

    .add-input {
      display: flex;
      padding: 8px;

    }

    .add-list {
      overflow-y: auto;
      left: 0;
      top: calc(100% + 8px);
      width: 100%;
      z-index: 2;
      font-weight: 500 !important;
      box-sizing: border-box;
      color: map-get($shades, mute) !important;
      row-gap: 4px;
      display: flex;
      flex-direction: column;
      background-color: map-get($shades, white);
      max-height: 0px;
      transition-duration: 0.25s;

      .add-item {
        border-radius: calc(map-get($sizes, s) - 4px);
        padding: map-get($sizes, xs);

        &:hover {
          background-color: map-get($shades, light);
        }
      }
    }
  }


  padding: map-get($sizes, m);
  border-radius: 12px;

  &:hover {
    background: map-get($shades, light);

    .info-icon {
      visibility: visible;

      &:hover {
        background-color: map-get($shades, light);
        cursor: pointer;
        color: map-get($shades, mute);
      }
    }
  }

  input, textarea, select {
    width: 100%;
  }

  .info-icon {
    visibility: hidden;
    display: flex;
    position: absolute;
    left: calc(100% - 24px - 1rem);
    top: calc(50% + 30px / 2);
    z-index: 2;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transform: translateY(-50%);
    color: map-get($shades, sliver);
    font-size: 20px;
  }

  &.has-label-inline {
    display: flex !important;
    flex-wrap: nowrap;
    .info-icon{
      top: calc(50%);
    }
    .label {
      text-align: end;
      margin-bottom: 0 !important;
    }
  }
}

.is-required {
  .label:before {
    content: "*";
    color: $secondary;
    margin-right: 4px;
  }
}

.label {
  color: map-get($shades, mute);
  font-weight: 400;
  font-size: 17px;
  max-width: 120px;
  min-width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 24px;
  margin-bottom: .5rem;
}

input[type='range'] {
  width: 210px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

input[type='range'], input[type='file'], input[type='color'], input[type='date'], input[type='week'], input[type='time'], input[type='datetime-local'] {
  padding: 2px 8px !important;
  position: relative;
}

input[type='color']::-webkit-color-swatch {
  border-radius: 8px;
}

input[type="file"] {
  display: none;
}


input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 12px;
  border-radius: 20px;
  background: #AAA;
}

input[type='range']::-webkit-slider-thumb {
  position: relative;
  height: 24px;
  width: 24px;
  margin-top: -6px;
  background: $primary;
  border-radius: 50%;
  border: 2px solid white;
}

input[type='range']::-webkit-slider-thumb::before {
  position: absolute;
  content: '';
  height: 12px; /* equal to height of runnable track */
  width: 500px; /* make this bigger than the widest range input element */
  left: -502px; /* this should be -2px - width */
  top: 8px; /* don't change this */
  background: #ff0000;
}

input {
  &::-webkit-calendar-picker-indicator {
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 3px;
  }

}

input {
  &.is {
    flex: 1;
    position: relative;


    &-s {
      @include InputTinyAtr;
    }

    &-l {
      @include InputLargeAtr;
    }

  }


}

.form-check {
  width: 210px;
  padding: map-get($sizes,s) ;
  border-radius: map-get($sizes,s);
  margin: map-get($sizes,m)  ;
  position: relative;
  display: flex;
  align-items: center;
  // TODO disable Checked and disable Uncheck
  &:hover {
    background: map-get(map-get($colors, shade), light);
  }
  label {
    &.unchecked {
      span{
        color: map-get($shades, sliver);
      }
      background: map-get($shades, light) !important;
      &:after {
        visibility: hidden;
         }
    }
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    left:map-get($sizes,m);
    top: calc(map-get($sizes,s) );
    border: 1px solid map-get($shades, sliver);
    //background: map-get(map-get($colors, primary), main) ;
    border-radius: 6px;
    &.checked{
      background: map-get(map-get($colors, shade), disable) !important;
    }
    &:hover {
      background: map-get(map-get($colors, primary), light);
    }

    &:after {
      content: '';
      position: absolute;
      border: 6px solid #fff;
      left: 3px;
      top: 4px;
      background: map-get($shades, white);
      transform-origin: center center;
      clip-path: polygon(21% 40%, 21% 40%, 8% 54%, 25% 68%, 36% 77%, 47% 65%, 48% 64%, 64% 47%, 75% 35%, 93% 13%, 81% 4%, 36% 54%);
    }

    &:hover::after {
      opacity: 0.5;
    }
  }

  span {
    position: relative;
    white-space: nowrap;
    width: 300px;
    left: 24px;
    top: 0;
  }

  input[type=checkbox] {
    height: 16px !important;
    visibility: hidden;

    &:checked + label {
      background: map-get(map-get($colors, primary), main);
      &.checked {
        span{
          color: map-get($shades, sliver);
        }
        background: map-get($shades, disable);
      }
    }
    &:checked + label:after {
      opacity: 1;
    }

  }
}

//file
.form-file-upload {
  width: 100%;
  text-align: center;
  position: relative;



}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 120px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: map-get($shades, white);

  &:hover {
    background-color: map-get($shades, light);
  }
}
.label-file-upload-l{
  height: 320px;
  @extend .label-file-upload
}
.label-file-upload-s{
  height: 48px;
  @extend .label-file-upload
}


.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.file-upload {
  .loading {
    display: none;
  }

  &.on-upload {
    .loading {
      position: absolute;
      display: flex;
      z-index: 2;
      content: "";
      box-sizing: inherit;
      height: 64px;
      width: 64px;
      top: calc(50% - (64px * .5));
      left: calc(50% - (64px * .5));
      border: 8px solid transparent;
      border-radius: 50%;
      animation: loading-spinner 1s ease infinite;
      border-top-color: map-get($shades, white) !important;
    }

  }

  position: relative;
  height: 100%;

  .control-file-upload {
    position: absolute;
    top: map-get($sizes, s);
    right: map-get($sizes, s);
    display: none;
    background-color: map-get($shades, mute);
  }

  img {
    height: calc(map-get($sizes, xl) * 12);
  }

  &:hover {
    .control-file-upload {
      display: flex;
    }

    img {
      filter: blur(2px);
    }
  }
}
