
.hi-5xl {
    font-size: clamp(6.25rem, 3.1977rem + 11.6279vw, 12.5rem);
    line-height: normal;

}

.hi-4xl {
    font-size:calc(48px + (96 - 48) * ((100vw - 300px) / (1440 - 300)));
    line-height: normal;
}

.hi-3xl {
    font-size:calc(32px + (96 - 48) * ((100vw - 300px) / (1440 - 300)));
    line-height: normal;

}

.hi-xxl {
    font-size:calc(28px + (72 - 48) * ((100vw - 300px) / (1440 - 300)));
    line-height: normal;

}

.hi-xl {
    font-size: clamp(1.5rem, 0.672rem + 2.586vw, 3rem);
    line-height: normal;

}

.hi-l {
    font-size: 48px;
    line-height: normal;

}
.hi-relative {
    position: relative;
    margin: auto;
}
.mt-h-x{
    margin-top: 10vh;
}
.mt-h-xlx{
    margin-top: 20vh;
}
.mt-h-lx{
    margin-top: 30vh;
}
.mt-h-mx{
    margin-top: 40vh;
}
.mt-h-sx{
    margin-top: 50vh;
}
.mr-w-x{
    margin-right: 10vw;
}
.mr-w-2x{
    margin-right: 20vw;
}
.mr-w-3x{
    margin-right: 30vw;
}
.mr-w-4x{
    margin-right: 40vw;
}
.mt-w-5x{
    margin-right: 50vw;
}
.ml-w-x{
    margin-left: 10vw;
}
.ml-w-2x{
    margin-left: 20vw;
}
.ml-w-3x{
    margin-left: 30vw;
}
.ml-w-4x{
    margin-left: 40vw;
}
.ml-w-5x{
    margin-left: 50vw;
}
.mb-h-x{
    margin-bottom: 10vh;
}
.mb-h-xlx{
    margin-bottom: 20vh;
}
.mb-h-lx{
    margin-bottom: 30vh;
}
.mb-h-mx{
    margin-bottom: 40vh;
}
.mb-h-sx{
    margin-bottom: 50vh;
}
.hi-flex{
    display: flex;
}
.hi-flex-wrap{
    display: flex;
    flex-wrap: wrap;
}
.hi-block{
    display: block;
}
.hi-full-x{
    width: 100%;
}
.hi-full-y{
    height: 100%;
}
.hi-fill-x{
    flex: 1;
}
.hi-fill-Y{
    height: auto;
}
.hi-card-title{
    font-weight: 900;
}
.hi-uppercase{
    text-transform: uppercase;
}
.hi-text-light{
    font-weight: 100;
}
.hi-text-italic{
    font-style: italic;
}

.hi-text-semi{
    font-weight: 600;
}
.hi-text-bold{
    font-weight: 700;
}
.hi-text-black{
    font-weight: 800;
}
.hi-text-heavy{
    font-weight: 900;
}

.hi-text-justify{
    text-align: justify;
}
.hi-text-left{
    text-align: left;
}
.hi-text-right{
    text-align: right;
}
.hi-text-center{
    text-align: center!important;
}
.divider-child div{
    padding: 0px 8px;
}
.hi-sticky{
    position: sticky;
}
.hi-sticky.align-center{
    width: 90%;
    margin-left: 5%;
}
.hi-bottom{
    bottom: 2vh;
}
