@import "../variable/variables";
img,video{
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-name: imageComing;
  @each $offSetName, $offSet in $sizes {
    &.size-#{$offSetName} {
      &>svg{
        width: $offSet;
        height: $offSet;
      }
      width: $offSet;
      height: $offSet;
    }
    &.size-y-#{$offSetName} {
      height: $offSet;
      width: auto;
    }

    &.size-x-#{$offSetName} {
      width: $offSet;
      height: auto;

    }
    &.size-#{$offSetName}-x2 {
      width: calc(#{$offSet} * 10);
      height: calc(#{$offSet} * 10);
    }
    &.size-y-#{$offSetName}-x2 {
      height: calc(#{$offSet} * 10);
      width: auto;

    }

    &.size-x-#{$offSetName}-x2 {
      width: calc(#{$offSet} * 10);
      height: auto;
    }
  }
}
