@import "../variable/variables";

.table-section {
  overflow-x: auto;
  flex-grow: 1;
  //overflow-y: hidden;
  .cell-selector {

  }

  max-width: 100%;

  table {
    border-collapse: collapse;
    overflow: auto;
    border-spacing: 0;
    table-layout: fixed;
    //height: 80vh;
    min-width: 100%;
    @each $offSetSize, $offSet in $sizes {
      tr.p-#{$offSetSize} {
        td, th {
          padding: $offSet !important;
        }

        &-x {
          td, th {
            padding: 0 !important;
          }
        }
      }
      tr.px-#{$offSetSize} {
        td, th {
          padding-left: $offSet !important;
          padding-right: $offSet !important;
        }
      }
      tr.py-#{$offSetSize} {
        td, th {
          padding-top: $offSet !important;
          padding-bottom: $offSet !important;
        }
      }
    }


    &.table-not-border {

      tr {
        td, th {
          white-space: normal;
          border: 0 !important;
        }
      }
    }

    &.table-strip-border {

      tr {
        td {
          white-space: normal;
          border-bottom: 2px solid map-get($shades, light) !important;
          border-top: 2px solid map-get($shades, light) !important;
          border-left: 0 !important;
          border-right: 0 !important;
        }

        th {
          white-space: normal;
          border-bottom: 2px solid map-get($shades, light) !important;
          border-left: 0 !important;
          border-right: 0 !important;
          border-top: 0 !important;
        }
      }
    }

    &.table-striped {
      thead {
        background-color: map-get($shades, light);
      }

      tbody {
        tr {
          border-radius: 8px;

          &:nth-child(odd) {
            background-color: map-get($shades, white);

          }

          &:nth-child(even) {
            background-color: map-get($shades, light);

          }

        }
      }

    }

    tr {
      display: table-row;

      &:last-child {
        td:last-child {
          border-radius: 0 0 8px 0;
        }

        td:first-child {
          border-radius: 0 0 0 8px;

        }

      }

      td {
        min-width: 100px;
        display: table-cell;
        white-space: normal;
        border: 0.25px solid map-get($shades, sliver);
        align-items: center;


      }

      th {
        word-break: break-all;
        display: table-cell;
        box-sizing: border-box;
        align-items: center;
        border: 0.25px solid map-get($shades, sliver);
        justify-content: center;

        &:first-child {
          border-radius: 8px 0 0 0 !important;


        }

        &:last-child {
          border-radius: 0 8px 0 0 !important;
        }
      }

    }

  }
}

