//COLOR
//primary
$primary-light: #eef3fc !default;
$primary: #485fc7 !default;
$primary-hover: #3e56c4 !default;
$primary-active: #3a51bb !default;
$primary-dark: #384fb8 !default;



$secondary-light: #feecf0 !default;
$secondary: #ff3860 !default;
$secondary-hover: #f03a5f !default;
$secondary-active: #ef2e55 !default;
$secondary-dark: #cc0f35 !default;
//shades
$shades: (
        white: #ffffff,
        light:  rgba(0,0,0,.04),
        sliver: rgba(0,0,0,.12),
        disable: #737373,
        darkgray: #525252,
        mute: #2b2b2b,
        outline: #121212,
        black: #0a0a0a
) !default;
$colors: (
        primary:(
                light:$primary-light,
                main:$primary,
                hover:$primary-hover,
                active:$primary-active,
                dark:$primary-dark,
        ),
        secondary:(
                light:$secondary-light,
                main:$secondary,
                hover:$secondary-hover,
                active:$secondary-active,
                dark:$secondary-dark,
        ),
        shade:(
                light:map-get($shades,light),
                main:map-get($shades,mute),
                hover:map-get($shades,black),
                active:map-get($shades,outline),
                dark:map-get($shades,black)
        ),
) !default;

$InputSize: (
        s:28px,
        '':36px,
        l:42px,
        xl:52px,
) !default; // Size
$sizes: (
        x:0,
        xs: 4px,
        s: 8px,
        m: 16px,
        l: 24px,
        xl: 32px,
        xxl: 48px
) !default;


$text-regular: 400;
$text-bold: 600;
$text-extraBold: 700;

$container-xxl: 1440px;
$container-xl: 1280px;
$container-l: 1080px;
$container-m: 920px;
$container-s: 720px;
$container-xs: 420px;

$breakpoint-xxl: 1600px;
$breakpoint-xl: 1400px;
$breakpoint-l: 1200px;
$breakpoint-m: 1000px;
$breakpoint-s: 980px;
$breakpoint-xs: 500px;


@function get-contrasting-color($color,$background) {
        $whiteContrast: contrast( map-get($color,light),$background);
        $blackContrast: contrast( map-get($color,dark),$background);


        @if ($whiteContrast > $blackContrast) {
                @return map-get($color,light);  // Trả về màu chữ trắng cho màu nền tối
        } @else {
                @return map-get($color,dark);

        }
}
@function contrast($color1, $color2) {
        $luminance1: 0.2126 * red($color1) + 0.7152 * green($color1) + 0.0722 * blue($color1);
        $luminance2: 0.2126 * red($color2) + 0.7152 * green($color2) + 0.0722 * blue($color2);

        @return calc(abs($luminance1 - $luminance2) / max($luminance1, $luminance2))
;
}