.toasts-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: fixed;
  z-index: 9999;

  &.top-right {
    top: 16px;
    right: 16px;
  }

  &.top-left {
    top: 16px;
    left: 16px;
  }

  &.top-center {
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom-left {
    bottom: 16px;
    left: 16px;
  }

  &.bottom-center {
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom-right {
    bottom: 16px;
    right: 16px;
  }

  .toast {
    background-color: map-get($shades, white);
    border-radius: map-get($sizes, s);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    overflow: hidden;
    .toast-icon{
      padding-right: map-get($sizes, s);
      display: flex;
      align-items: center;
    }
    /* rest of the properties */
    animation: slide-in 0.4s ease-in-out forwards;
    &-dismissed {
      animation: slide-out 0.4s ease-in-out forwards;
    }
    .toast-message {
      display: flex;
      justify-content: space-between;
      padding: map-get($sizes, m);
      width: 250px;
    }
  }


}


@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toast-dismissed {
  animation: slide-out 0.4s ease-in-out forwards;
}

@keyframes slide-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}