@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@900&family=Montserrat:wght@400;600;700&family=Nunito:wght@1000&family=Poppins:ital,wght@1,900&family=Readex+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import "./Duck/DuckDesignSystem/Scss/variable/variables";
@import "./Duck/Font/DuckIcon/src/logofont";

* {
  transition-duration: 0.3s;
}

html {
  scroll-behavior: smooth;
}

input,
button,
textarea,
#stone-markup {
  font-family: 'SF Pro Display', sans-serif;
}

body {
  background-color: white;
}

/*html{*/
/*    scroll-behavior: smooth;*/
/*}*/
.text-slogan {
  font-size: 64px;
  line-height: 64px;
  font-weight: 800;
}

.text-valid {
  color: #7992C4;
}

.Main {
  padding-top: 75px;
}

a {
  color: #1a1a1a;
  font-weight: 600;
  cursor: pointer;
  /*text-decoration: underline;*/
}

.logo {
  cursor: pointer;
}

.text-sayhi {
  font-family: 'Readex Pro', sans-serif;
  color: rgba(128, 128, 128, 0.3);
  writing-mode: vertical-rl;
  font-size: 128px;
  word-break: keep-all;
  letter-spacing: 2px;
  white-space: nowrap;
  font-weight: 800;
  cursor: context-menu;
}

.font-text {
  font-family: 'SF Pro Display', sans-serif;
}

.block-sayhi {
  position: absolute;
  top: 10%;
  left: 0%;
  width: 128px;
  font-weight: 800;
}

.hi-bg-first-look {
  background-color: white;
  opacity: 1;
  background-image: linear-gradient(rgba(193, 193, 193, 0.27) 2.5px, transparent 2.5px), linear-gradient(to right, rgba(193, 193, 193, 0.37) 2.5px, rgba(255, 255, 255, 0.37) 2.5px);
  background-size: 50px 50px;
}

.hi-black {
  color: #1a1a1a;
}

.hi-bg-light {
  background-color: #eee;
}

.hi-bg-white {
  background-color: white;
}

.hi-bg-black {
  background-color: var(--black-color);
}

.expand-element {
  display: none;

  &.active {
    display: flex;
  }
}

:root {
  --card-height: 100vw;
  --card-margin: 0;
  --card-top-offset: 1em;
  --numcards: 4;
  --outline-width: 0px;
  --primary-color: #7992C4;
  --white-color: white;
  --opacity-color: rgba(255, 255, 255, 0.4);
  --black-color: #1a1a1a;
  --light-color: #eee;
  --green-color: #39C541;
  --outline-color: linear-gradient(278.28deg, #FF0000 -79.39%, #FFC01D -59%, #FBFF3D -35.52%, #81FF6D -11.38%, #8DFFD6 10.34%, #AAF0FF 27.82%, #BECCFF 52.26%, #ECD3FF 82.93%, #FFE9F5 109.72%, #FE0000 122.62%);
}

.hi-text-primary {
  color: var(--primary-color) !important;
}

.hi-text-gradient {
  background-size: 400% 400%;
  background: linear-gradient(#fff, #23a6d5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 2s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hi-text-white {
  color: var(--white-color) !important;
  fill: var(--white-color) !important;
}

.hi-green {
  color: var(--green-color) !important;
}

#card_1 {
  --index: 1;
  backdrop-filter: blur(15px);

}

#card_2 {
  --index: 2;
  background-color: black;

}

#card_3 {
  --index: 3;
  background: linear-gradient(278.28deg, #FF0000 -79.39%, #FFC01D -59%, #FBFF3D -35.52%, #81FF6D -11.38%, #8DFFD6 10.34%, #AAF0FF 27.82%, #BECCFF 52.26%, #ECD3FF 82.93%, #FFE9F5 109.72%, #FE0000 122.62%);


}

#card_4 {
  --index: 4;
  background-color: black;
  padding-top: 24px;
  /*height: 100vh;*/


}


#cards {
  list-style: none;
  /*margin: 128px;*/
}

.hi-intro-text {
  color: transparent;
  font-weight: 900;
  font-size: clamp(9.375rem, -10.28rem + 61.422vw, 45rem);
  -webkit-text-stroke: 1px map-get($shades, sliver);

}

.hi-letter-text {
  /*height: 80% !important;*/
  font-weight: 700;
  font-size: clamp(5rem, 3.5349rem + 5.5814vw, 8rem);
  line-height: normal;
}

ul {
  padding-inline-start: 0px;
}

.hi-bottom-text {
  position: absolute;
  bottom: 20%;
  width: 1000px;
  font-weight: 900;
  font-size: 8rem;
  right: auto;
  /* white */

  color: #FFFFFF;

  opacity: 0.2;
  z-index: -2;
}

.hi-discovery-text {
  font-weight: 800;
  font-size: 55px;
  line-height: 67px;

  background: linear-gradient(278.28deg, #FF0000 -79.39%, #FFC01D -59%, #FBFF3D -35.52%, #81FF6D -11.38%, #8DFFD6 10.34%, #AAF0FF 27.82%, #BECCFF 52.26%, #ECD3FF 82.93%, #FFE9F5 109.72%, #FE0000 122.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.hi-discovery-items > .item {
  position: revert;
  font-weight: 800;
  font-size: 55px;
  line-height: 67px;
  width: 50vw !important;
  /* identical to box height */

  text-transform: uppercase;

  /* white */

  color: rgba(255, 255, 255, 0.7);


}

.hi-discovery-items > .active {
  font-weight: 800;
  font-size: 96px;
  color: #FFFFFF;

}

.hi-discovery-items > .item:hover {
  background: linear-gradient(278.28deg, #FF0000 -79.39%, #FFC01D -59%, #FBFF3D -35.52%, #81FF6D -11.38%, #8DFFD6 10.34%, #AAF0FF 27.82%, #BECCFF 52.26%, #ECD3FF 82.93%, #FFE9F5 109.72%, #FE0000 122.62%);
  font-weight: 800;
  font-size: 96px;
  overflow: visible;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


}

.text-chudong {
  color: white;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 20px 0 20px 0;
}

.hi-py-4xl {
  padding-top: 128px;
  padding-bottom: 128px;
}

.hi-py-3xl {
  padding-top: 64px;
  padding-bottom: 64px;
}

.hi-py-xxl {
  padding-top: 32px;
  padding-bottom: 32px;
}

.hi-py-xl {
  padding-top: 16px;
  padding-bottom: 16px;
}

.hi-py-l {
  padding-top: 16px;
  padding-bottom: 16px;
}

g {
  height: 500px !important;
  width: 500px !important;
}

.hi-loop-x {
  white-space: nowrap;
  animation-name: loop-x;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

}

@keyframes loop-x {
  from {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 945, 0, 0, 1);

  }
  to {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1945, 0, 0, 1);
  }
}

.hi-hidden-x {
  is-overflow-x: hidden !important;;
}

.hi-hidden {
  overflow: scroll !important;;
}

.hi-hide {
  opacity: 0;
  transform: translateY(00%);
  transition: all 2s;
  transition-duration: 0s;
}

.hi-delay-x {
  transition-delay: 0.25s;
}

.hi-delay-xl {
  transition-delay: 0.5s;
}

.hi-delay-xxxl {
  transition-delay: 1s;
}

.hi-delay-3xl {
  transition-delay: 1.25s;
}

.hi-delay-4xl {
  transition-delay: 1.5s;
}

.hi-bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.hi-bg-gradient {
  background: rgb(238, 174, 202);
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
}

.hi-whitespace {
  margin-left: 8px;
}

.hi-discovery {
  overflow: visible;
  /*position: absolute;*/
  /*display: grid;*/
  /*place-items: center;*/
  /*z-index: 1;*/
}

section {
  height: 30vh;
}

canvas {
  height: 500px;
  /*right: 50%;*/


}

#ss {
  position: sticky;
  //left: 50%;
  top: 50%;
  transform: translate(0%, -50%);
  /*opacity: 1;*/
  z-index: 0;
}

#x-scroll {

}

.hi-bg-rainbow {
  background: linear-gradient(278.28deg, #FF0000 -79.39%, #FFC01D -59%, #FBFF3D -35.52%, #81FF6D -11.38%, #8DFFD6 10.34%, #AAF0FF 27.82%, #BECCFF 52.26%, #ECD3FF 82.93%, #FFE9F5 109.72%, #FE0000 122.62%);

}

.hi-bg-transparent {
  background-color: transparent;
}

.hi-welcome {
  color: var(--black-color);
  font-size: 512px;
  white-space: nowrap;
  position: sticky;
  opacity: 0;
  top: 40vh;
  transform: translateX(100%);
  z-index: 0;

}

@media screen and (max-width: $breakpoint-xs) {
  canvas {
    &#hero {
      transform: scale(.45);
    }
  }
}

#os {
  max-height: 100vh;
}

.chu-dong {
  position: sticky;
  top: 30vh;

}


#text-os1, #text-os2, #text-os3 {
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 1s;


}

.bg-blur {
  background: rgba(171, 171, 171, 0.09);
  border-radius: 16px;
  //backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  &-dark{
    background: rgba(236, 236, 236, 0.45);
    border-radius: 16px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    &-2{
      background: rgba(174, 174, 174, 0.45);
      border-radius: 16px;
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

}

#aaaa {
  position: sticky;
  /*right: 50%;*/

  padding-top: 20vh;
  z-index: 1;

  video {
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    transform: translateX(50%);
    background-size: cover;
  }
}

.lf-player-container {
  display: flex;
  flex: fit-content;
  overflow: hidden;
}

.player {
  animation: append-animate .4s linear;
}

.hi-element {
  display: flex;
  border-radius: 12px;
  padding: 4px 8px;
}

.hi-element > svg {
  margin-right: 4px;
}

.hi-element:hover {
  background-color: var(--light-color);
}

.hi-card-img {
  border-radius: 12px !important;
  /*overflow: auto;*/
  width: auto;
  height: auto;
  /*background-color: black; !* fallback color *!*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 1s;
}


@keyframes lineClamp {
  from {
    -webkit-line-clamp: 2;
  }
  to {
    -webkit-line-clamp: 5;
  }
}

.hi-btn {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  width: fit-content;
  margin: .25rem .125rem;
  padding: 0.15rem 0.55rem;
  background-color: var(--primary-color);
  border-radius: 12px;
}

.hi-btn-round {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  width: fit-content;
  margin: .25rem .125rem;
  padding: 0.85rem 1.75rem;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 1%, rgba(255, 255, 255, 0.37) 100%);
  filter: drop-shadow(0px 4px 11px rgba(255, 255, 255, 0.1));
  border-radius: 0.375rem;
  animation: linearColor 3s ease-in infinite;
}

@keyframes linearColor {
  0% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 99%, rgba(255, 255, 255, 0.37) 100%);
  }
  3% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 80%, rgba(255, 255, 255, 0.37) 100%);
  }
  6% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 70%, rgba(255, 255, 255, 0.37) 100%);

  }
  9% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 60%, rgba(255, 255, 255, 0.37) 100%);
  }
  12% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0.37) 100%);

  }
  15% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 40%, rgba(255, 255, 255, 0.37) 100%);

  }
  18% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 30%, rgba(255, 255, 255, 0.37) 100%);

  }
  21% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 20%, rgba(255, 255, 255, 0.37) 100%);

  }
  24% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 10%, rgba(255, 255, 255, 0.37) 100%);


  }
  27% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, #FFFFFF 0%, rgba(255, 255, 255, 0.37) 100%);

  }
  29% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, rgba(255, 255, 255, 0.37) 100%);

  }
  30%, 100% {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.37) 0%, rgba(255, 255, 255, 0.37) 100%);

  }
}

.hi-btn-round::before {
  content: "";
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 4px;
  background-color: var(--black-color);
  z-index: -1;
}

.hi-btn-round::after {
  content: attr(data);
  font-size: 16px;
  -webkit-background-clip: text;
  color: transparent;
}

.hi-btn-round:hover, .hi-btn-round:focus {
  -webkit-animation: 0;
  background: transparent;
  filter: drop-shadow(0px 4px 11px rgba(255, 255, 255, 0.3));


}

.hi-btn-round:hover::before, .hi-btn-round:focus::before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid #ffffff;

}

.hi-btn-icon {
  display: flex;
  padding: 0.25rem 0.25rem;
  align-items: center;
}

.scroll-section {
  /*position: sticky;*/
  /*top: 10vh;*/
  /*width: 400vw;*/
  height: 800vh !important;
  overflow: hidden;
  /*is-overflow-x: hidden;*/

}

#scrollElement {
  height: 70vh;
  position: fixed;
  top: 20vh;
  z-index: 0;
}

.menu-items {
  position: fixed;
  top: 0;
}

#scrollSection {
  position: sticky;
  background-color: var(--white-color) !important;
  z-index: 7001;

}

#bottomMenu {
  width: 100vw !important;
  position: fixed;
  z-index: 1000;
  top: 0;
  border-bottom: 0 solid #eee;

  .active {
    height: 4rem;
    width: 100%;
    position: fixed;
    top: 0px;
    transition: 0.3s linear;
    display: flex;
    justify-content: stretch;
    align-items: center;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    z-index: 40;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);

  }
}


#navMenu {
  width: 100%;

}

.hover-state {

  .hidden-element {
    display: flex !important;
  }


}

.hidden-element {
  display: flex !important;
}

.ui-element > svg {
  width: 100%;
  height: auto;
}

// discovery
.h-72 {
  line-height: 120%;
  font-weight: 700;
  font-size: 72px !important;
}

.has-background-image {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: contain;
    background-position: center;
  }
}


.esc-content {
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-name: esc-atr;
  animation-fill-mode: forwards;
  overflow: hidden;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  white-space: nowrap;

  &.active {
  }

  &:before {
    content: attr(data-text);
  }
}

#text-1 {
  //position: absolute;
  top: 5vh;
}

#text-2 {
  //position: relative;
  top: 40%;
}

#text-3 {
  //position: relative;
  top: 50%;
}

#text-4 {
  //position: relative;
  top: 70%;
}

.marquee {
  width: 200%;
  position: relative;
  display: flex;
  cursor: pointer;

  overflow-x: hidden;
  white-space: nowrap;


  div {
    width: 50% !important;
    animation-name: anim;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    color: map-get($shades, sliver);
    transition-duration: .0s;
    transform: translateX(-50%);
  }
}

@keyframes anim {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.text-unwrap {
  white-space: nowrap;
}

.toggle-animate.open {
  animation: bounce-in 1s ease-in;
}

.toggle-animate.close {
  animation: bounce-out 1s ease-in;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translateY(330px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(330px);
  }
}

.transition {
  transition: all 1s;
}

// TODO timeline
@keyframes timeline {
  from {
    max-width: 0;
  }
  to {
    max-width: 200vw;
  }
}

@keyframes schedule {
  from {
    max-width: 0;
  }
  to {
    max-width: 30vw;
  }
}

.time-line {
  user-select: none;
  position: relative;
  overflow-x: auto;
  animation: schedule 1s linear;


  .schedule {

    width:2800px;
    top: 25px;
    position: absolute;
    display: grid;
    grid-template-columns: repeat(48, 1fr);
    z-index: 2;

  }

  .stand {

    width: 2800px;
    position: relative;
    bottom: 0;

    .col {
      position: relative !important;

      div {
        position: sticky;
        left: 0;
      }
    }

    & > * {
      display: flex;
      align-items: end;
      height: 350px;
      border-left: 1px solid map-get($shades, "sliver");
      border-left-style: dashed;
    }
  }
}

.popup {
  position: fixed;
  display: flex;
  align-items: end;
  top: 0;
  left: 0;
  z-index: 12;
  max-width: 250px;
  transition: transform 330ms linear;

}

.product-card {
  .product-image {
    display: none !important;
  }

  .card-img {
    width: 100% !important;
    height: 240px !important;
    overflow: visible;

  }

  .product-hover {
    transition: transform .25s;
  }


  .product-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    row-gap: map-get($sizes, l);
    transition: color .15s ease;

    &:before {
      content: "";
      position: absolute;
      border-radius: 500px!important;

      z-index: -1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0px;
      transform: translateY(-100%);
      transition: height .15s;
      transition-timing-function: ease-out;
    }

    .btn-icon {
      position: relative;
      width: map-get($sizes, xxl);
      height: map-get($sizes, xxl);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: map-get($shades, sliver);

      &:after {
        content: "";
        border-radius: 50%;
        position: absolute;
        width: map-get($sizes, xs);
        height: map-get($sizes, xs);
        z-index: -1;
        left: calc((map-get($sizes, xxl)) / 2);
        top: calc((map-get($sizes, xxl)) / 2);
        transform: translate(-50%, -50%);
        transition: width .55s, height .55s;
        transition-timing-function: ease-out;
      }
    }
  }

  &:hover {

    .product-hover {
      transform-origin: center;
    }

    .product-content {
      color: map-get($shades, white);

      &:before {
        left: 0;
        bottom: 0;
        height: 100%;
        border-radius: 0px!important;
        width: 100%;
        transform: translateY(0%);
        background-color: map-get($shades, mute);
      }

      .link-hover {
        color: map-get($shades, white);
      }

      .btn-icon {
        color: map-get($shades, white);

        &:after {
          border-radius: 50%;
          width: map-get($sizes, xxl);
          height: map-get($sizes, xxl);
          transform: translate(-50%, -50%);
          background-color: map-get($shades, black);
        }
      }
    }
  }
}

#skull {
  canvas {
  }

  @media (max-width: $breakpoint-l) {
    canvas {
      height: 90vh !important;
    }
  }
  @media (max-width: $breakpoint-xs) {
    canvas {
      height: 70vh !important;
    }
  }
}

#triggerElement {
  @media (max-width: $breakpoint-l) {
    padding: 0 24px;
  }
  @media (max-width: $breakpoint-xs) {
    padding: 0 12px;
  }
}

#workEle {
  height: 160vw;
  @media (max-width: $breakpoint-l) {
    height: 260vw;
  }
  @media (max-width: $breakpoint-xs) {
    height: 360vw;
  }

}

/* Modal.css */
.modal-open {
  overflow: hidden;
}

.grid-line {
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 75px *2 - 50px);
  opacity: 1;
  display: flex;
  align-items: center;
  background-image: repeating-linear-gradient(90deg, color(srgb 1.09 1.09 1.09), rgb(240, 181, 142) 1px, transparent 1px, transparent 2px);
  --mask: linear-gradient(90deg, #000 30%, rgba(0, 0, 0, .6) 70%, rgba(0, 0, 0, .5));
  background-position: 50% 100%;
  mask-image: var(--mask);
  -webkit-mask-image: var(--mask);
  animation: gradientAnimation 2s linear infinite;

}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.grid-blink {
  position: relative;
  width: 100%;
  height: 240px;
  row-gap: 12px;
  background: linear-gradient(to right, rgba(122, 122, 122, 0.44), #ffffff, rgba(122, 122, 122, 0.44));
  background-size: 400% 400%;
  animation:  ↘ 8s ease infinite;
  svg {
    position: relative;
    z-index: 1;
  }





}
.🦆 {
 animation: ↘ 12s linear infinite;

}


@keyframes ↘ {
  0%{background-position:0% 34%}
  50%{background-position:100% 67%}
  100%{background-position:0% 34%}
}
.IhpBYHaz_0 {
  stroke-dasharray: 1502 1504;
  stroke-dashoffset: 1503;
  animation: IhpBYHaz_draw_0 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_1 {
  stroke-dasharray: 247 249;
  stroke-dashoffset: 248;
  animation: IhpBYHaz_draw_1 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_2 {
  stroke-dasharray: 247 249;
  stroke-dashoffset: 248;
  animation: IhpBYHaz_draw_2 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_3 {
  stroke-dasharray: 682 684;
  stroke-dashoffset: 683;
  animation: IhpBYHaz_draw_3 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_4 {
  stroke-dasharray: 682 684;
  stroke-dashoffset: 683;
  animation: IhpBYHaz_draw_4 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_5 {
  stroke-dasharray: 682 684;
  stroke-dashoffset: 683;
  animation: IhpBYHaz_draw_5 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_6 {
  stroke-dasharray: 117 119;
  stroke-dashoffset: 118;
  animation: IhpBYHaz_draw_6 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_7 {
  stroke-dasharray: 169 171;
  stroke-dashoffset: 170;
  animation: IhpBYHaz_draw_7 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_8 {
  stroke-dasharray: 146 148;
  stroke-dashoffset: 147;
  animation: IhpBYHaz_draw_8 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_9 {
  stroke-dasharray: 17 19;
  stroke-dashoffset: 18;
  animation: IhpBYHaz_draw_9 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_10 {
  stroke-dasharray: 277 279;
  stroke-dashoffset: 278;
  animation: IhpBYHaz_draw_10 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_11 {
  stroke-dasharray: 75 77;
  stroke-dashoffset: 76;
  animation: IhpBYHaz_draw_11 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_12 {
  stroke-dasharray: 219 221;
  stroke-dashoffset: 220;
  animation: IhpBYHaz_draw_12 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_13 {
  stroke-dasharray: 310 312;
  stroke-dashoffset: 311;
  animation: IhpBYHaz_draw_13 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_14 {
  stroke-dasharray: 237 239;
  stroke-dashoffset: 238;
  animation: IhpBYHaz_draw_14 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_15 {
  stroke-dasharray: 237 239;
  stroke-dashoffset: 238;
  animation: IhpBYHaz_draw_15 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

.IhpBYHaz_16 {
  stroke-dasharray: 1502 1504;
  stroke-dashoffset: 1503;
  animation: IhpBYHaz_draw_16 12200ms ease-out 0ms infinite, IhpBYHaz_fade 12200ms linear 0ms infinite;
}

@keyframes IhpBYHaz_draw {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_fade {
  0% {
    stroke-opacity: 1;
  }
  96.72131147540983% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}

@keyframes IhpBYHaz_draw_0 {
  6.557377049180328% {
    stroke-dashoffset: 1503
  }
  19.93923408233448% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_1 {
  19.93923408233448% {
    stroke-dashoffset: 248
  }
  22.14728500996071% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_2 {
  22.147285009960715% {
    stroke-dashoffset: 248
  }
  24.355335937586947% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_3 {
  24.355335937586947% {
    stroke-dashoffset: 683
  }
  30.43637941971887% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_4 {
  30.43637941971887% {
    stroke-dashoffset: 683
  }
  36.51742290185081% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_5 {
  36.51742290185081% {
    stroke-dashoffset: 683
  }
  42.59846638398273% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_6 {
  42.59846638398273% {
    stroke-dashoffset: 118
  }
  43.64907126083715% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_7 {
  43.64907126083715% {
    stroke-dashoffset: 170
  }
  45.162654558000284% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_8 {
  45.162654558000284% {
    stroke-dashoffset: 147
  }
  46.47145893848841% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_9 {
  46.47145893848841% {
    stroke-dashoffset: 18
  }
  46.63172069936451% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_10 {
  46.631720699364514% {
    stroke-dashoffset: 278
  }
  49.106874561784245% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_11 {
  49.106874561784245% {
    stroke-dashoffset: 76
  }
  49.78353532992777% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_12 {
  49.78353532992777% {
    stroke-dashoffset: 220
  }
  51.74229018508007% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_13 {
  51.74229018508007% {
    stroke-dashoffset: 311
  }
  54.51125727577265% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_14 {
  54.51125727577265% {
    stroke-dashoffset: 238
  }
  56.63027389180105% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_15 {
  56.63027389180105% {
    stroke-dashoffset: 238
  }
  58.74929050782946% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes IhpBYHaz_draw_16 {
  58.74929050782946% {
    stroke-dashoffset: 1503
  }
  72.1311475409836% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-gap: 10px;
}

@media screen and (max-width: 767px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 5px;
  }
}

.gallery__item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.gallery__item:hover {
  transform: scale(1.05);
}
.scroll-wrapper {
  @media screen and (max-width: 500px) {
    height: 320px;
    .gallery {
      width: 80vw;
      height: 320px !important;

      .cards {
        width: 3rem;
        height: 256px !important;

        li {
          width: 3rem;
          height: 256px !important;
        }
      }
    }

  }
  cursor: none !important;
  overflow: hidden;
  height: calc(100vh - 100px - 180px);
  width: 100%;
  z-index: 100;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .gallery {

    position: absolute;
    top: 0!important;
    width: calc(100vw);
    height: calc(100vh - 100px - 180px);
    overflow: hidden;

    .cards {
      position: absolute;
      width: 14rem;
      height: calc(100vh - 100px - 180px);
      top: 0%;
      left: 50%;
      transform: translate(-100%, 0%);

      li {
        cursor: none !important;

        list-style: none;
        padding: 0;
        margin: 0;
        width: 520px;
        height: calc(100vh - 100px - 200px);
        text-align: center;
        font-family: sans-serif;
        background-color: #9d7cce;
        position: absolute;
        top: 0;
        border-radius: 0.8rem;

        img {
          width: 100%;
          border-radius: 16px;
          height: 100%;
          object-fit: cover;
        }

        &::marker {
          content: "" !important;
        }
      }
    }

    .drag-proxy {
      visibility: hidden;
      position: absolute;
    }
  }
}