@import "viewport";

.is-remaining {
  flex-grow: 1;
}

.is-center {
  display: flex;
  align-items: center;
  justify-content: center;

  &.vertical {
    flex-direction: column;
  }
}

.is-flex {
  display: flex;

  &.wrap {
    flex-wrap: wrap;
  }
  &.unwrap {
    flex-wrap: nowrap;
  }

  &.vertical {
    flex-direction: column;
  }

  //for laptop screen
  @media (max-width: $breakpoint-l) {
    &.l-vertical {
      flex-direction: column !important;
      height: 100%;
    }
  }
  // for mobile screen
  @media (max-width: $breakpoint-xs) {
    &.s-vertical {
      flex-direction: column;
      height: 100%;
    }
  }
}

.is-relative {
  position: relative;
}

.is-absolute {
  position: absolute;
}

.is-fixed {
  position: fixed;
}

.is-block {
  display: block !important;
}

.is-sticky {
  position: sticky;

  &.top-sticky {
    top: var(--top-position);
  }
}

.is-grid {
  display: grid;
}

.is-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-between {
  align-content: space-between;
}

.align-center {
  align-items: center;
}

.jt-center {
  justify-content: center;
}

.jt-end {
  justify-content: flex-end;
}

.jt-start {
  justify-content: flex-start;
}

.jt-between {
  justify-content: space-between;
}

.jt-around {
  justify-content: space-around;
}

.border {
  border: 1px solid map-get($shades, sliver);
  border-radius:  map-get($sizes, m);

  &-l {
    border-left: 1px solid map-get($shades, sliver);
  }

  &-r {
    border-right: 1px solid map-get($shades, sliver);
  }

  &-t {
    border-top: 1px solid map-get($shades, sliver);
  }

  &-b {
    border-bottom: 1px solid map-get($shades, sliver);
  }
}

.border-light {
  border: 1px solid map-get($shades, darkgray);
  border-radius:  map-get($sizes, m);

  &-l {
    border-left: 1px solid map-get($shades, darkgray);
  }

  &-r {
    border-right: 1px solid map-get($shades, darkgray);
  }

  &-t {
    border-top: 1px solid map-get($shades, darkgray);
  }

  &-b {
    border-bottom: 1px solid map-get($shades, darkgray);
  }
}

.border.dash {
  border: dashed 1px map-get($shades, sliver);
}

.divider {
  &.vertical {
    height: 100%;
    width: 1px;
  }

  width: 100%;
  height: 1px;
  background-color: map-get($shades, darkgray);
  margin: 20px 0 20px 0;

  &-x2 {
    background-color: map-get($shades, darkgray);
    height: 3px !important;
  }
}

.is-grow {
  display: flex;
  flex: 1;

}

[class*="col-"] {
  float: left;
}

.columns {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;

  &.gap {
    &-x {
      column-gap: 0;
      row-gap: 0;
    }

    &-s {
      --gap: 4px;
      column-gap: var(--gap);
      row-gap: 4px;
    }

    &-m {
      --gap: 12px;
      column-gap: var(--gap);
      row-gap: 12px;

    }

    &-l {
      --gap: 32px;
      column-gap: var(--gap);
      row-gap: 32px;
    }
  }

  &.unwrap {
    flex-wrap: nowrap;
  }

  //for all screen
  &.vertical {
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
  }

  &.vertical-revert {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    height: 100%;
  }

  //for laptop screen
  @media (max-width: $breakpoint-l) {
    &.l-vertical {
      flex-direction: column !important;
      flex-wrap: nowrap;
      height: 100%;
    }
  }
  // for mobile screen
  @media (max-width: $breakpoint-xs) {
    &.s-vertical {
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;
    }
  }

  &.layout {
    //for all screen layout-1-1-1
    @for $i from 1 through 6 {
      &-#{$i} {
        @for $j from 1 through 6 {
          &-#{$j} {
            .col:nth-child(1) {
              flex: $i;
            }

            .col:nth-child(2) {
              flex: $j;
            }

            @for $k from 1 through 6 {
              &-#{$k} {
                .col:nth-child(1) {
                  flex: $i;
                }

                .col:nth-child(2) {
                  flex: $j;
                }

                .col:nth-child(3) {
                  flex: $k;
                }
              }
            }
          }
        }
      }
    }
    //for l-screen  layout-l-1-1-1
    @media (max-width: $breakpoint-l) {
      &-l {
        @for $i from 1 through 6 {
          &-#{$i} {
            @for $j from 1 through 6 {
              &-#{$j} {
                .col:nth-child(1) {
                  flex: $i !important;
                }

                .col:nth-child(2) {
                  flex: $j !important;
                }

                @for $k from 1 through 6 {
                  &-#{$k} {
                    .col:nth-child(1) {
                      flex: $i !important;
                    }

                    .col:nth-child(2) {
                      flex: $j !important;
                    }

                    .col:nth-child(3) {
                      flex: $k !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    //for mobile layout-xs-1-1-1
    @media (max-width: $breakpoint-xs) {
      &-xs {
        @for $i from 1 through 6 {
          &-#{$i} {
            @for $j from 1 through 6 {
              &-#{$j} {
                .col:nth-child(1) {
                  flex: $i !important;
                }

                .col:nth-child(2) {
                  flex: $j !important;
                }

                @for $k from 1 through 6 {
                  &-#{$k} {
                    .col:nth-child(1) {
                      flex: $i !important;
                    }

                    .col:nth-child(2) {
                      flex: $j !important;
                    }

                    .col:nth-child(3) {
                      flex: $k !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .col {
    //width: 100%;
    box-sizing: border-box;
    flex: 1 0 0;
    animation-duration: 1.2s;
    @for $i from 2 through 6 {
      &-#{$i} {
        width: 100%;
        flex: $i;
      }
    }

    .is-full-y {
      height: 100%;
    }
  }
}

@mixin gridAtr($i) {
  gap: 16px;
  display: grid;
  grid-template-columns: repeat($i, 1fr);
  .col {
    grid-column: span 1;
    @for $i from 2 through 6 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
  &.wrapper{
    gap:0 !important;
    border-top: 1px solid map-get($shades, sliver);
    border-left: 1px solid map-get($shades, sliver);
    .col{
      border-bottom: 1px solid map-get($shades, sliver);
      border-right: 1px solid map-get($shades, sliver);
    }
  }
}

.grid {

  @include gridAtr(6);

  @for $i from 1 through 6 {
    &-#{$i} {
      @include gridAtr($i);

      &.gap {
        &-x {
          gap: 0 !important;
        }

        &-s {
          --gap: 4px;
          grid-gap: var(--gap);
        }

        &-m {
          --gap: 12px;
          grid-gap: var(--gap);
        }

        &-l {
          --gap: 32px;
          grid-gap: var(--gap);
        }
      }
    }


  }
  // for mobile grid-xs-3
  @media (max-width: $breakpoint-xs) {
    &-xs {
      @for $i from 1 through 6 {
        &-#{$i} {
          @include gridAtr($i);

          &.gap {
            &-x {
              gap: 0 !important;
            }

            &-s {
              --gap: 4px;
              grid-gap: var(--gap);
            }

            &-m {
              --gap: 12px;
              grid-gap: var(--gap);
            }

            &-l {
              --gap: 32px;
              grid-gap: var(--gap);
            }
          }
        }
      }
    }
  }
  // for laptop grid-l-3
  @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-l) {
    &-l {
      @for $i from 1 through 6 {
        &-#{$i} {
          @include gridAtr($i);
        }
      }
    }
  }


}


//for tablet

//for mobile
@media (min-width: 0) and (max-width: $breakpoint-xs) {
  .s-vertical-revert {
    flex-direction: column-reverse !important;
  }
  .s-revert {
    flex-direction: row-reverse !important;
    flex-wrap: nowrap;

    .col {
      flex: 1 !important;
    }

    @for $i from 2 through 12 {
      .col-#{$i} {
        width: 100%;
        flex: 1;
      }
    }
  }
}

@media (max-width: $breakpoint-l) {
  .l-vertical-revert {
    flex-direction: column-reverse !important;
  }
  .l-revert {
    flex-direction: row-reverse !important;
    flex-wrap: nowrap;

    .col {
      flex: 1 !important;
    }

    @for $i from 2 through 12 {
      .col-#{$i} {
        width: 100%;
        flex: 1;
      }
    }
  }
}