@import "../variable/variables";
::-webkit-scrollbar-track {
  background-color: map-get($shades,light);
  border-radius: 20px;
  width: 2px;

}

::-webkit-scrollbar {
  height: 5px!important;
  width: 5px;
  border-radius: 20px;
  background-color:  map-get($shades,light);
}

::-webkit-scrollbar-thumb {

  border-radius: 20px;
  background-color: map-get($shades,sliver);
}
