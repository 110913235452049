@import "../variable/variables";

@each $offSetSize, $offSet in $sizes {
  .m-#{$offSetSize} {
    margin: $offSet !important;

    &-x {
      margin: 0 !important;
    }
  }
  .mx-#{$offSetSize} {
    margin-left: $offSet !important;
    margin-right: $offSet !important;
  }
  .my-#{$offSetSize} {
    margin-top: $offSet !important;
    margin-bottom: $offSet !important;
  }
  .ml-#{$offSetSize} {
    margin-left: $offSet !important;
  }
  .mr-#{$offSetSize} {
    margin-right: $offSet !important;
  }
  .mt-#{$offSetSize} {
    margin-top: $offSet !important;
  }
  .mb-#{$offSetSize} {
    margin-bottom: $offSet !important;
  }
  .p-#{$offSetSize} {
    padding: $offSet !important;

    &-x {
      padding: 0 !important;
    }
  }
  .px-#{$offSetSize} {
    padding-left: $offSet !important;
    padding-right: $offSet !important;
  }
  .py-#{$offSetSize} {
    padding-top: $offSet !important;
    padding-bottom: $offSet !important;
  }
  .pl-#{$offSetSize} {
    padding-left: $offSet !important;
  }
  .pr-#{$offSetSize} {
    padding-right: $offSet !important;
  }

  .pt-#{$offSetSize} {
    padding-top: $offSet !important;
  }
  .pb-#{$offSetSize} {
    padding-bottom: $offSet !important;
  }
}

@each $offSetSize, $offSet in $sizes {
  .gap-#{$offSetSize} {
    &:not(.grid):not(.columns) > * {
      margin: $offSet;
    }
  }
  .gapx-#{$offSetSize} {
    &:not(.grid):not(.columns) > * {
      &:not(:first-child):not(:last-child){
        margin-right: $offSet;
        margin-left: $offSet;
      }


      &:first-child {
        margin-right: $offSet;
      }

      &:last-child {
        margin-left: $offSet;
      }
    }
  }
  .gapy-#{$offSetSize} {
    &:not(.grid):not(.columns) > * {
      &:first-child {
        margin-bottom: $offSet;
        margin-top: $offSet;
      }
      &:not(:first-child):not(:last-child){
        margin-top: $offSet;
        margin-bottom: $offSet;
      }
      &:last-child {
        margin-top: $offSet;
        margin-bottom:$offSet
      }


    }
  }
}

@media (max-width: $breakpoint-s) {
  @each $offSetSize, $offSet in $sizes {
    .m-mobile-#{$offSetSize} {
      margin: map-get($sizes, xs) !important;
    }
    .mx-mobile-#{$offSetSize} {
      margin-left: map-get($sizes, xs) !important;
      margin-right: map-get($sizes, xs) !important;
    }
    .ml-mobile-#{$offSetSize} {
      margin-left: map-get($sizes, xs) !important;
    }
    .mr-mobile-#{$offSetSize} {
      margin-right: map-get($sizes, xs) !important;
    }
    .my-mobile-#{$offSetSize} {
      margin-top: map-get($sizes, xs) !important;
      margin-bottom: map-get($sizes, xs) !important;
    }
    .mt-mobile-#{$offSetSize} {
      margin-top: map-get($sizes, xs) !important;
    }
    .mb-mobile-#{$offSetSize} {
      margin-bottom: map-get($sizes, xs) !important;
    }
    .p-mobile-#{$offSetSize} {
      padding: map-get($sizes, xs) !important;
    }
    .px-mobile-#{$offSetSize} {
      padding-left: map-get($sizes, xs) !important;
      padding-right: map-get($sizes, xs) !important;
    }
    .py-mobile-#{$offSetSize} {
      padding-bottom: map-get($sizes, xs) !important;
      padding-top: map-get($sizes, xs) !important;
    }
    .pl-mobile-#{$offSetSize} {
      padding-left: map-get($sizes, xs) !important;
    }
    .pr-mobile-#{$offSetSize} {
      padding-right: map-get($sizes, xs) !important;
    }

    .pt-mobile-#{$offSetSize} {
      padding-top: map-get($sizes, xs) !important;
    }
    .pb-mobile-#{$offSetSize} {
      padding-bottom: map-get($sizes, xs) !important;
    }
  }
}