@font-face {
  font-family: 'Duck';

  src: url('./Duck.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@import "./icon";

.icon {
  font-family: 'Duck', serif;
  padding: 0 4px ;
  display: flex;
  align-items: center;
  justify-content: center;
  @each $iconName, $iconContent in $icon {
    &.icon-#{$iconName}{
      position: relative;
      &:before {
        display: inline-block;
        content: $iconContent;
      }
    }
  }
}