@import "../variable/variables";
@mixin between-sizes($higher,$lower) {
  @media (min-width: $lower)  and (max-width: $higher) {
    margin: 0 auto!important;
    width: 100%;
    @content; }
}
@mixin verticalAtr($name){
  @include between-sizes($breakpoint-xxl, 0){
    &.#{$name}{
      @content;
    }
  }
  @include between-sizes($breakpoint-xl, 0){
    &.xl-#{$name}{
      @content;
    }
  }
  @include between-sizes($breakpoint-l, 0){
    &.l-#{$name}{
      @content;
    }
  }
  @include between-sizes($breakpoint-m, 0){
    &.m-#{$name}{
      @content;
    }
  }
  @include between-sizes($breakpoint-s, 0){
    &.s-#{$name}{
      @content;
    }
  }
  @include between-sizes($breakpoint-xs, 0){
    &.xs-#{$name}{
      @content;
    }
  }
}
.container-full{
  width: 100vw!important;

}
.container-xxl{
  @include between-sizes(9999px, $breakpoint-xxl){
    max-width: $container-xxl;
  }
  @include between-sizes($breakpoint-xxl, $breakpoint-xl){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-xl, $breakpoint-l){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-l, $breakpoint-m){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-m, $breakpoint-s){
    max-width: 100%;
    padding: 0 8px;
  }
  @include between-sizes($breakpoint-s, $breakpoint-xs){
    max-width: 100%;
    padding: 12px!important;

  }
  @include between-sizes($breakpoint-xs, 0){
    max-width: 100%;
    padding: 12px!important;
  }
}


.container-xl{

  @include between-sizes(9999px, $breakpoint-xxl){
    max-width: $container-xl;
  }
  @include between-sizes($breakpoint-xxl, $breakpoint-xl){
    max-width: $container-xl;
  }
  @include between-sizes($breakpoint-xl, $breakpoint-l){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-l, $breakpoint-m){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-m, $breakpoint-s){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-s, $breakpoint-xs){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-xs, 0){
    max-width: 100%;
    padding: 0 8px;

  }
}

.container-l{
  @include between-sizes(9999px, $breakpoint-xxl){
    max-width: $container-l;
  }
  @include between-sizes($breakpoint-xxl, $breakpoint-xl){
    max-width: $container-l;
  }
  @include between-sizes($breakpoint-xl, $breakpoint-l){
    max-width: $container-l;
  }
  @include between-sizes($breakpoint-l, $breakpoint-m){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-m, $breakpoint-s){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-s, $breakpoint-xs){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-xs, 0){
    padding: 0 8px;
    max-width: 100%;

  }
}

.container-m{
  @include between-sizes(9999px, $breakpoint-xxl){
    max-width: $container-m;

  }
  @include between-sizes($breakpoint-xxl, $breakpoint-xl){
    max-width: $container-m;

  }
  @include between-sizes($breakpoint-xl, $breakpoint-l){
    max-width: $container-m;

  }
  @include between-sizes($breakpoint-l, $breakpoint-m){
    max-width: $container-m;
  }
  @include between-sizes($breakpoint-m, $breakpoint-s){
    padding: 0 8px;
    max-width: 100%;

  }
  @include between-sizes($breakpoint-s, $breakpoint-xs){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-xs, 0){
    max-width: 100%;
    padding: 0 8px;

  }
}

.container-s{
  @include between-sizes(9999px, $breakpoint-xxl){
    max-width: $container-s;
  }
  @include between-sizes($breakpoint-xxl, $breakpoint-xl){
    max-width: $container-s;
  }
  @include between-sizes($breakpoint-xl, $breakpoint-l){
    max-width: $container-s;
  }
  @include between-sizes($breakpoint-l, $breakpoint-m){
    max-width: $container-s;
  }
  @include between-sizes($breakpoint-m, $breakpoint-s){
    max-width: $container-s;

  }
  @include between-sizes($breakpoint-s, $breakpoint-xs){
    max-width: 100%;
    padding: 0 8px;

  }
  @include between-sizes($breakpoint-xs, 0){
    max-width: 100%;
    padding: 0 8px;

  }
}
.container-xs{
  @include between-sizes(9999px, $breakpoint-xxl){
    max-width: $container-xs;
  }
  @include between-sizes($breakpoint-xxl, $breakpoint-xl){
    max-width: $container-xs;
  }
  @include between-sizes($breakpoint-xl, $breakpoint-l){
    max-width: $container-xs;
  }
  @include between-sizes($breakpoint-l, $breakpoint-m){
    max-width: $container-xs;
  }
  @include between-sizes($breakpoint-m, $breakpoint-s){
    max-width: $container-xs;

  }
  @include between-sizes($breakpoint-s, $breakpoint-xs){
    max-width: $container-xs;

  }
  @include between-sizes($breakpoint-xs, 0){
    max-width: 100%;
    padding: 0 8px;

  }
}