@import "../variable/variables";
@import "../Animation/animation";

@mixin duckFocus($value) {
  box-shadow: 0 0 0 3px $value;
}

@mixin noneFocus() {
  box-shadow: 0 0 0 0;
}

@mixin loadingAtr() {
  content: "";
  position: absolute !important;
  box-sizing: inherit;
  height: 12px;
  width: 12px;
  left: calc(50% - (12px * .5));
  border: 2px solid transparent;
  border-radius: 50%;
  animation: loading-spinner 1s ease infinite;

}

@mixin btnMediumAtr {
  box-sizing: border-box !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: map-get($InputSize, '') !important;
  padding: 0 12px !important;
  font-size: 13px !important;
  line-height: 120% !important;
  border-radius: 8px;
  width: fit-content;
  white-space: nowrap;
  font-weight: 500;

}

@mixin btnTinyAtr {
  height: map-get($InputSize, s) !important;
  padding: 0 8px !important;
  font-size: 13px !important;
  line-height: 1 !important;
  @include svgAtr(12);
}


@mixin btnLargeAtr {
  height: map-get($InputSize, l) !important;

  padding: 0 16px !important;
  font-size: 15px !important;
  line-height: 1.6 !important;
  @include svgAtr(16);
}


@mixin svgAtr($size) {
  > svg {
    height: #{$size}px !important;
    width: #{$size}px !important;
  }
}

.segment {
  position: relative;
  width: 100%;
  display: flex;
  background: map-get($shades, white);
  border: 1px solid map-get($shades, sliver);
  padding: map-get($sizes, xs);
  border-radius: map-get($sizes, s) + 2px;

  .segment-sub {
    opacity: 1;
    contain: strict;
    background: map-get($shades, light);
    position: absolute;
    top: 4px;
    width: 54px;
    left: 0;
    border-radius: map-get($sizes, s);
    z-index: 0;
    height: 28px;
    transition: .15s ease;
    transition-property: width, transform, opacity;
  }

  div {
    flex: 1;

    .btn {
      flex: 1;
      width: 100%;
      @include btnTinyAtr;
      color: map-get($shades, disable);
      background-color: unset !important;
      border: 1px solid transparent;

      &:hover, &.hover {
        color: map-get(map-get($colors, shade), hover) !important;
      }

      &:active, &.active, &.press {
        color: map-get(map-get($colors, shade), hover) !important;
        background: map-get($shades, sliver) !important;
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
      }
    }
  }

}

.btn {
  user-select: none;

  &-full {
    flex: 1;
  }

  &-s {
    @include btnTinyAtr;
  }

  &-l {
    @include btnLargeAtr;

  }


  @include btnMediumAtr;
  background-color: map-get(map-get($colors, shade), main);
  color: map-get($shades, white);
  //color: get-contrasting-color( map-get($colors, shade),map-get(map-get($colors, shade), main)) ;
  @each $ColorName, $Color in $colors {
    &-#{$ColorName} {
      background-color: map-get($Color, main);
      color: get-contrasting-color(map-get($colors, $ColorName), map-get($Color, main));
    }
  }

}

.btn, button {
  position: relative;
  border: 1px solid map-get(map-get($colors, shade), main);

  &:hover, &.hover {
    //@include btnMediumAtr;
    background-color: map-get(map-get($colors, shade), hover);
    color: map-get($shades, white);
    border: 1px solid transparent;
  }

  &:active, &.active, &.press {
    @include duckFocus(map-get(map-get($colors, shade), light));
    background-color: map-get(map-get($colors, shade), hover);
    color: map-get($shades, white);
  }

  &.loading {
    color: transparent !important;
    background-color: map-get(map-get($colors, shade), hover);

    &:hover {
      color: transparent !important;
    }
  }


  &.loading:before {
    @include loadingAtr;
    border-top-color: map-get($shades, white) !important;
  }

  &.disable {
    cursor: not-allowed;
    border: 1px solid map-get($shades, sliver) !important;
    color: (map-get($shades, shade), sliver) !important;
    background-color: map-get($shades, disable) !important;
  }

  &-has-icon {
    display: flex;
    column-gap: 6px;

    svg {
      stroke: white !important;
      height: 12px;
      width: 12px;
    }
  }

  &-outline {
    border: 1px solid map-get(map-get($colors, shade), hover);
    background-color: unset !important;
    color: map-get(map-get($colors, shade), main);

    &:hover, &.hover {
      border: 1px solid map-get(map-get($colors, shade), hover);
      color: get-contrasting-color(map-get($colors, shade), map-get(map-get($colors, shade), light));
      background-color: map-get(map-get($colors, shade), light) !important;
    }

    &:active, &.active, &.press {
      @include duckFocus(map-get(map-get($colors, shade), light));
      color: map-get(map-get($colors, shade), dark);
      border: 1px solid map-get(map-get($colors, shade), hover);
      background-color: map-get(map-get($colors, shade), light) !important;
    }

    svg {
      stroke: map-get(map-get($colors, shade), main) !important;
      height: 16px;
      width: 16px;
    }

    &.loading:before {
      @include loadingAtr;
      border-top-color: map-get(map-get($colors, shade), dark) !important;
    }

    &.disable {
      border: 1px solid map-get($shades, sliver) !important;
      color: map-get($shades, sliver) !important;
      background-color: unset !important;
    }

  }

  &-fill {
    color: map-get(map-get($colors, shade), main);
    background-color: map-get(map-get($colors, shade), light) !important;
    border: 1px solid transparent;

    &:hover, &.hover, {
      color: map-get(map-get($colors, shade), hover) !important;
      border: 1px solid map-get(map-get($colors, shade), light);
    }

    &:active, &.active, &.press {
      color: map-get(map-get($colors, shade), hover) !important;
      background-color: map-get(map-get($colors, shade), light) !important;
    }

    svg {
      stroke: map-get(map-get($colors, shade), main) !important;
      height: 16px;
      width: 16px;
    }

    &.loading:before {
      @include loadingAtr;
      border-top-color: map-get(map-get($colors, shade), dark) !important;
    }

    &.disable {
      color: map-get($shades, disable) !important;;
      background-color: map-get($shades, light) !important;
      border: 1px solid map-get($shades, light) !important;
    }
  }

  &-ghost {
    color: map-get(map-get($colors, shade), main);
    background-color: unset !important;
    border: 1px solid transparent;

    &:hover, &.hover {
      color: map-get(map-get($colors, shade), hover) !important;
      border: 1px solid map-get(map-get($colors, shade), light);
    }

    &:active, &.active, &.press {
      color: map-get(map-get($colors, shade), hover) !important;
    }

    svg {
      stroke: map-get(map-get($colors, shade), main) !important;
      height: 16px;
      width: 16px;
    }

    &.loading:before {
      @include loadingAtr;
      border-top-color: map-get(map-get($colors, shade), dark) !important;
    }

    &.disable {
      border: 0px !important;
      background-color: unset !important;
      color: map-get($shades, disable) !important;
    }
  }

  &-group {
    display: flex;

    & > *:active, &.active, &.press {
      @include noneFocus();
    }

    & > *:not(:last-child) {
      border-radius: 0 !important;
      border-right: 0;
    }

    > :first-child {
      border-radius: 8px 0 0 8px !important;
    }

    > :last-child {
      border-radius: 0 8px 8px 0 !important;
    }
  }
}

@mixin buttonColorAtr($ColorName,$Color) {
  .btn-#{$ColorName} {
    border: 1px solid map-get($Color, main);

    &:hover, &.hover {
      //@include btnMediumAtr;
      background-color: map-get($Color, hover);
      color: get-contrasting-color(map-get($colors, $ColorName), map-get($Color, hover));
      border: 1px solid transparent;
    }

    &:active, &.active, &.press {
      @include duckFocus(map-get($Color, light));
      background-color: map-get($Color, hover);
      color: get-contrasting-color($Color, map-get($Color, hover));
    }

    &.loading {
      color: transparent;
      background-color: map-get($Color, hover);

      &:hover {
        color: transparent !important;
      }
    }


    &.loading:before {
      @include loadingAtr;
      border-top-color: map-get($shades, white) !important;
    }

    &.disable {
      cursor: not-allowed;
      border: 1px solid map-get($shades, sliver) !important;
      color: (map-get($shades, shade), white) !important;
      background-color: map-get($shades, disable) !important;
    }

    &.btn-has-icon {
      display: flex;
      column-gap: 6px;

      svg {
        stroke: white !important;
        height: 12px;
        width: 12px;
      }
    }

    &.btn-outline {
      border: 1px solid map-get($Color, main);
      background-color: unset !important;
      color: map-get($Color, main);

      &:hover, &.hover {
        border: 1px solid map-get($Color, hover);
        color: map-get($Color, hover) !important;
        background-color: map-get($Color, light) !important;

      }

      &:active, &.active, &.press {
        @include duckFocus(map-get($Color, light));
        color: map-get($Color, dark);
        border: 1px solid map-get($Color, hover);
        background-color: map-get($Color, light) !important;
      }

      svg {
        stroke: map-get($Color, main) !important;
        height: 16px;
        width: 16px;
      }

      &.loading{
        &:before {
          @include loadingAtr;
          border-top-color: map-get($Color, dark) !important;
        }
        &:hover{
          color:transparent!important;
        }
      }


      &.disable {
        border: 1px solid map-get($shades, sliver) !important;
        color: map-get($shades, disable) !important;
        background-color: unset !important;
      }

    }

    &.btn-fill {
      color: map-get($Color, main);
      background-color: map-get($Color, light) !important;
      border: 1px solid transparent;

      &:hover, &.hover, {
        color: map-get($Color, hover) !important;
        border: 1px solid map-get($Color, light);
      }

      &:active, &.active, &.press {
        color: map-get($Color, hover) !important;
        background-color: map-get($Color, light) !important;
      }

      svg {
        stroke: map-get($Color, main) !important;
        height: 16px;
        width: 16px;
      }

      &.loading{
        &:before {
          @include loadingAtr;
          border-top-color: map-get($Color, dark) !important;
        }
        &:hover{
          color:transparent!important;
        }
      }

      &.disable {
        color: map-get($shades, disable) !important;;
        background-color: map-get($shades, light) !important;
        border: 1px solid map-get($shades, light) !important;
      }
    }

    &.btn-ghost {
      color: map-get($Color, main);
      background-color: unset !important;
      border: 1px solid transparent;

      &:hover, &.hover {
        color: map-get($Color, hover) !important;
        border: 1px solid map-get($Color, light);
      }

      &:active, &.active, &.press {
        color: map-get($Color, hover) !important;
      }

      svg {
        stroke: map-get($Color, main) !important;
        height: 16px;
        width: 16px;
      }
      &.loading{
        &:before {
          @include loadingAtr;
          border-top-color: map-get($Color, dark) !important;
        }
        &:hover{
          color:transparent!important;
        }
      }


      &.disable {
        border: 0px !important;
        background-color: unset !important;
        color: map-get($shades, disable) !important;
      }
    }

    &.btn-group {
      display: flex;

      & > * {
        border-radius: 0 !important;
        border-top: 1px;
        border-bottom: 1px;
        border-left: 0;
        border-right: 0;
      }

      &:first-child {
        border-radius: 8px 0 0 8px !important;
      }
    }
  }
}

@each $ColorName, $Color in $colors {
  @if $ColorName != shade {
    @include buttonColorAtr($ColorName, $Color)
  }

}

@mixin btnSwitchAtr($SizeName,$Size) {
  $offSet: $Size / map-get($InputSize,'');
  .input-switch {
    &.switch {
      width: $Size * 1.8;
      height: $Size;

      & input {
        &:checked {
          & + .slider:before {
            transform: translateX($Size - 8px * $offSet) !important;
          }
        }
      }

      .slider {
        &:before {
          height: $Size - 8px * $offSet;
          width: $Size - 8px * $offSet;
          left: 4px * $offSet;
          bottom: 4px * $offSet;

        }

      }
    }
  }
}

@each $SizeName, $Size in $InputSize {
  @include btnSwitchAtr("", 24px)
}

.input-switch {
  position: relative;
  display: inline-block;
  width: calc(24px * 1.6);
  height: 24px;

  & input {
    height: 24px !important;
    border: 50px !important;
    opacity: 0;

    &:checked {
      & + .slider {
        background-color: $primary;
      }

      & + .slider:before {
        transform: translateX(24px - 10px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $primary-dark;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($shades, sliver);
    transition: .2s;
    border-radius: 50px !important;

    &.disable {
      background: map-get($shades, disable) !important;

      &:before {
        background-color: map-get($shades, white) !important;
      }
    }

    &:before {
      position: absolute;
      content: "";
      height: calc(24px - 8px);
      width: calc(24px - 8px);
      left: 4px;
      bottom: 4px;
      border-radius: 50px !important;
      background-color: white;
      -webkit-transition: .2s;
      transition: .2s;
    }
  }
}
