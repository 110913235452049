@import "../variable/variables";

@mixin ratioAtr($pre,$suf) {
  img, video {
    &.ratio-#{$suf}-#{$pre} {
      aspect-ratio: $suf/$pre;
    }

    &.ratio-#{$pre}-#{$suf} {
      aspect-ratio: $pre / $suf;
    }

    object-fit: cover;
    background-position: center;
  }
}

@include ratioAtr(16, 9);
@include ratioAtr(21, 9);
@include ratioAtr(10, 9);
@include ratioAtr(4, 3);
@include ratioAtr(3, 2);
@include ratioAtr(1, 1);


.hover-visible {
  display: none;

  &:hover {
    display: unset;
  }
}

&.slide-cards {
  scroll-behavior: smooth;
  position: sticky;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: map-get($sizes, xl);
  flex: fit-content;
  min-height: 350px;
  overflow: auto;
  padding: map-get($sizes, xl);
  @media screen and (max-width: $breakpoint-l) {
    padding: 0;
    gap: map-get($sizes, xs);
  }
  border-radius: map-get($sizes, xs);
  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  overflow: hidden !important;
  border-radius: map-get($sizes, xs);
  display: flex;
  position: relative;
  flex-wrap: nowrap !important;
  flex-direction: row;
  animation: all 2s;
  transition-duration: .5s;
  @include verticalAtr("vertical") {
    flex-direction: column !important;
  }



  &.vertical {
    flex-direction: column !important;

    .card-img {
      img, video {
        object-fit: cover;
        background-position: center;
      }
    }
    .card-content{
      width: 100%;
    }
  }

  .card-img {
    width: fit-content;
    position: relative;
    display: flex;
    overflow: hidden;
    height: 100%;
    transition-duration: 1.2s;
  }

  .card-content {
    min-width: 66% !important;
    flex: 1;


    .card-title {
      margin-bottom: 12px;
    }
  }
}
