.duration-x {
  transition-duration: 0ms;
  animation-duration: 0ms;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.upComingElement {
  transform: translate3d(0, 0, 0);
  animation-duration: 1.25s;
  animation: upComing .8s ease-in;
}

@keyframes upComing {
  0% {
    opacity: 0;
    transform: translate3d(0, 160px, 80px);
  }

  50%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.downComingElement {
  transform: translate3d(0, 0, 0);
  animation-delay: .2s;
  animation: downComing .2s linear;
}

@keyframes downComing {
  0% {
    opacity: 0;
    transform: translate(-60px, 0);
  }

  50%, 100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.opa {
  transform: translate3d(0, 0px, 0px);
  opacity: 1;
}

@keyframes esc-atr {
  from {
    max-width: 200px;
  }
  to {
    max-width: 0;
  }
}

@keyframes alignText {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes width-animate {
  from {
    max-width: 0;
  }
  to {
    max-width: 100vw;
  }
}

@keyframes height-animate {
  from {
    max-width: 0;
  }
  to {
    max-width: 100vw;
  }
}

@keyframes append-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hoverAnimate {
  0% {
    background-size: 200% 100%;

    background: linear-gradient(to top, rgba(10, 10, 10, 1) 0%, rgba(0, 0, 0, 0) 50%);
  }
  100% {
    background: linear-gradient(to top, rgba(10, 10, 10, 1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

@keyframes imageComing {
  0% {
    opacity: 0;    
    transform: scale(105%);

  }
  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

.expand{
  animation: expand .7s ease-out;
}
.expand-vertical{
  animation: expand-vertical .7s ease-out;
}
@keyframes expand-vertical {
  from{
    max-height:0;
  }
  to{
    max-height:556px;
  }
}
.collapse{
  animation: collapse 1.7s ease-out;
}

@keyframes expand {
  from{
    max-width:0;
  }
  to{
    max-width:556px;
  }
}

@keyframes collapse {
  from{
    max-width:556px;
  }
  to{
    max-width:0;
  }
}
.fromLeftToRight{
  animation: fromLeftToRight .8s ease-out;
}
@keyframes fromLeftToRight {
  from{
    transform:translateX(400px);
  }
  to{
    transform:translateX(0px);
  }
}
.fromBotToTop{
  animation: fromBotToTop .8s ease-out;
}
@keyframes fromBotToTop {
  from{
    transform:translateY(400px);
  }
  to{
    transform:translateY(0px);
  }
}

