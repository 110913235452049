
.cursor {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  align-items: center;
  justify-content: center;
  width: 48px !important;
  height: 48px !important;
  border-radius: 32px;
  border: 3px solid white;
  background-color: red !important;
  mix-blend-mode: normal !important;
  //transition-delay: .1s;
  transition: all .2s ease-in;

  &:before {
    font-family: 'Duck', serif;
    content: "A";
    font-size: 32px;
    color: white;
    position: absolute;
    top: 50%;
    left: -50%;
    transform: translate(0, -50%);
  }

  &:after {
    font-family: 'Duck', serif;
    content: "F";
    font-size: 32px;
    color: white;
    position: absolute;
    top: 50%;
    right: -50%;
    transform: translate(0, -50%);
  }

  &-in {
    width: 24px;
    height: 24px;
    border-radius: 32px;
    //mix-blend-mode: difference;
    background-color: white !important;
    position: relative;
    top: 20%;
    left: 20%;
    transition-delay: 1s;
  }
}