.hero {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.is-quarter {
    height: 25vh !important;
  }

  &.is-half {
    height: 50vh !important;
  }

  &.is-one-third {
    height: 33vh !important;
  }

  &.is-three-quarter {
    height: 75vh !important;
  }

  &.is-full {
    height: 100vh !important;
  }

  .hero-content {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
  }

  &.has-background {

    > img {
      pointer-events: none;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }
  &.hero-bg{
    background: linear-gradient(180deg, #E8E8E8 0.06%, rgba(254, 240, 241, 0.00) 99.94%);
  }
}

.x-{
  &quarter {
    height: 25vh !important;
  }

  &half {
    height: 50vh !important;
  }

  &one-third {
    height: 33vh !important;
  }

  &three-quarter {
    height: 75vh !important;
  }
}
.y-{
  &quarter {
    height: 25vh !important;
  }

  &half {
    height: 50vh !important;
  }

  &one-third {
    height: 33vh !important;
  }

  &three-quarter {
    height: 75vh !important;
  }

}
